import { useState } from "react";

import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Link,
  Spinner,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import {
  CustomImage,
  CustomLink,
  DnsDetailModal,
  FlexSideImage,
  ImageDetailModal,
  MediaCenter,
  PreviewButton,
} from "../components";
import { finishCreateUser } from "../services/api";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { handleCRPInput, providedUrlStringIsValid } from "../utils";
import { useUtils } from "../context/UtilsContext";

function NewUser() {
  const [newUserState, setNewUserState] = useState({
    crp: "",
    name: "",
    whatsapp: "",
    mainImage: "",
    googleAnalyticsId: "",
    aboutImage: "",
    logo: "",
    email: "",
    webUrl: "",
  });
  const sideImages = [
    "https://images.unsplash.com/photo-1485217988980-11786ced9454?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1448932223592-d1fc686e76ea?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1480082036272-2711d14cf965?q=10&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  // @ts-ignore
  const { copyToClipboard } = useUtils();
  const [showImageModal, setShowImageModal] = useState("");
  const [imageKey, setImageKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [contentResponse, setContentResponse] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showDnsModal, setShowDnsModal] = useState(false);

  const navigate = useNavigate();
  const [newUserStep, setNewUserStep] = useState(0);
  const { t } = useTranslation();
  const trans = (key: string) => t(`newUser.${key}`);
  // @ts-ignore
  const { user } = useAuth();
  const token = user?.stsTokenManager?.accessToken;

  const openMediaCenter = (key: string, genericIndex?: number) => {
    const realIndex = genericIndex ? genericIndex - 1 : 0;
    setImageKey(`${key}${genericIndex ? `[${realIndex}]` : ""}`);
    setShowModal(true);
  };

  const drawImage = (imageUrl: string) =>
    imageUrl && (
      <CustomImage
        image={imageUrl}
        onClick={(e: string) => setShowImageModal(e)}
      />
    );

  const drawImageInput = (key: string, label: string) => (
    <FormControl>
      <FormLabel>{label || key}</FormLabel>
      {/* @ts-ignore */}
      {drawImage(newUserState[key]?.url)}
      <Button onClick={() => openMediaCenter(key)}>
        {/* @ts-ignore */}
        {newUserState[key]?.url
          ? t("generic.changeImage")
          : t("generic.newImage")}{" "}
      </Button>
    </FormControl>
  );

  const handleImageChange = (url: string, description?: string) => {
    setNewUserState({
      // @ts-ignore
      ...newUserState,
      [imageKey]: {
        description,
        url,
      },
    });
    setImageKey("");
    setShowModal(false);
  };

  const handleContentByStep = () => {
    switch (newUserStep) {
      case 0:
        return (
          <>
            <Heading fontSize={"2xl"}>{trans("welcome")}</Heading>
            <Text color="gray.400">{trans("fillYourData")}</Text>
            <Divider />
            <SimpleGrid columns={2} spacing={2}>
              <FormControl>
                <FormLabel>{trans("name")}</FormLabel>
                <Input
                  value={newUserState.name}
                  onChange={(e) =>
                    setNewUserState({ ...newUserState, name: e.target.value })
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>{trans("whatsapp")}</FormLabel>
                <Input
                  value={newUserState.whatsapp}
                  placeholder={trans("whatsappPlaceholder")}
                  maxLength={11}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      setNewUserState({
                        ...newUserState,
                        whatsapp: e.target.value,
                      });
                    }
                  }}
                />
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("whatsappHint")}
                </Text>
              </FormControl>
              <FormControl>
                {drawImageInput("mainImage", trans("mainImage"))}
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("mainImageHint")}
                </Text>
              </FormControl>
            </SimpleGrid>
          </>
        );
      case 1:
        return (
          <>
            <Heading fontSize={"2xl"}>{trans("letsContinue")}</Heading>
            <Text color="gray.400">{trans("nonRequiredFields")}</Text>
            <Divider />
            <SimpleGrid columns={2} spacing={6}>
              <FormControl>
                <FormLabel>{trans("googleAnalyticsId")}</FormLabel>
                <Input
                  value={newUserState.googleAnalyticsId}
                  placeholder="G-XXXXXXX"
                  onChange={(e) =>
                    setNewUserState({
                      ...newUserState,
                      googleAnalyticsId: e.target.value,
                    })
                  }
                />
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("analyctsHint")}
                </Text>
                <Link
                  href="https://support.google.com/analytics/answer/9304153?hl=pt-BR"
                  color="blue.400"
                  target="_blank"
                >
                  {trans("readMore")}
                </Link>
              </FormControl>
              <FormControl>
                <FormLabel>{trans("crp")}</FormLabel>
                <Input
                  maxLength={8}
                  placeholder="xx/xxxxx"
                  value={newUserState.crp}
                  onChange={(e) =>
                    setNewUserState({
                      ...newUserState,
                      crp: handleCRPInput(e.target.value),
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>{trans("email")}</FormLabel>
                <Input
                  value={newUserState.email}
                  placeholder="email@email.com"
                  type="email"
                  onChange={(e) =>
                    setNewUserState({ ...newUserState, email: e.target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>{trans("webUrl")}</FormLabel>
                <Input
                  placeholder="seudominio.com"
                  value={newUserState.webUrl}
                  errorBorderColor="red.300"
                  isInvalid={!providedUrlStringIsValid(newUserState.webUrl)}
                  onChange={(e) =>
                    setNewUserState({ ...newUserState, webUrl: e.target.value })
                  }
                />
                {!providedUrlStringIsValid(newUserState.webUrl) && (
                  <Text fontSize="xs" color="red">
                    {t("config.urlInvalid")}
                  </Text>
                )}
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("webUrlTip")}
                </Text>
                <CustomLink
                  onClick={() => setShowDnsModal(true)}
                  text={trans("dnsInstructions")}
                />
              </FormControl>
              <FormControl>
                {drawImageInput("aboutImage", trans("aboutImage"))}
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("aboutImageHint")}
                </Text>
              </FormControl>
              <FormControl>
                {drawImageInput("logo", trans("logo"))}
                <Text marginTop={2} color="gray.400" fontSize="xs">
                  {trans("logoHint")}
                </Text>
              </FormControl>
            </SimpleGrid>
          </>
        );
      case 2:
        return (
          <>
            <CheckCircleIcon color={"blue.400"} w={16} h={16} />
            <br />
            <Heading fontSize={"2xl"}>{trans("siteCreated")}</Heading>
            <Text whiteSpace="pre-wrap" color="gray.400">
              {trans("siteNextSteps")}{" "}
            </Text>
            <br />
            <SimpleGrid columns={2} spacing={4}>
              <Link
                href={`https://${contentResponse.host}`}
                fontWeight="bold"
                color="pink.200"
                target="_blank"
              >
                {contentResponse.host}
              </Link>
              <Button
                onClick={() =>
                  copyToClipboard(`https://${contentResponse.host}`)
                }
                colorScheme={"blue"}
                size="xs"
                variant="ghost"
              >
                {trans("copyUrl")}
              </Button>
            </SimpleGrid>
            <Divider />
            <br />
            <PreviewButton fullWidth url={contentResponse.id} />
            <Button
              onClick={() => navigate("/dashboard")}
              colorScheme={"blue"}
              variant={"solid"}
            >
              {trans("goToAdmin")}
            </Button>
          </>
        );
    }
  };

  const isFinalStep = newUserStep === 1;

  function normalizeString(str: string) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/\s+/g, "") // Remove spaces
      .replace(/[^a-z0-9]/gi, ""); // Remove special characters
  }

  const handleCreateNewUser = async () => {
    const {
      crp,
      name,
      whatsapp,
      mainImage,
      googleAnalyticsId,
      aboutImage,
      logo,
      email,
    } = newUserState;
    let normalized = normalizeString(name);
    // @ts-ignore
    const data = {
      crp,
      name,
      whatsapp,
      mainImage,
      googleAnalyticsId,
      aboutImage,
      logo,
      email,
      key: normalized,
      host: `${normalized}.${process.env.REACT_APP_BASE_URL}`,
    };
    setIsLoading(true);
    const response = await finishCreateUser(token, data);
    setIsLoading(false);
    setContentResponse({ ...response, host: data.host });
    if (response?.texts) {
      setNewUserStep(newUserStep + 1);
    }
  };

  const allowNextButton = providedUrlStringIsValid(newUserState.webUrl)
  return (
    <>
      {showModal && (
        <MediaCenter
          onClose={() => setShowModal(false)}
          selectImage={(e, x) => handleImageChange(e, x)}
        />
      )}
      {showImageModal && (
        <ImageDetailModal
          onClose={() => setShowImageModal("")}
          image={showImageModal}
        />
      )}
      {showDnsModal && (
        <DnsDetailModal onClose={() => setShowDnsModal(false)} />
      )}
      <Stack minH={"93vh"} direction={{ base: "column", md: "row" }}>
        <FlexSideImage image={sideImages[newUserStep]} />
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            {handleContentByStep()}
            {newUserStep !== 2 && (
              <Stack spacing={6}>
                <Divider />
                {newUserStep !== 0 && (
                  <Button
                    isDisabled={newUserStep === 0}
                    onClick={() => setNewUserStep(newUserStep - 1)}
                    colorScheme={"blue"}
                    variant={"outline"}
                  >
                    {trans("back")}
                  </Button>
                )}

                <Button
                  isDisabled={
                    !allowNextButton ||
                    !newUserState.name ||
                    !newUserState.whatsapp ||
                    !newUserState.mainImage
                  }
                  onClick={() =>
                    isFinalStep
                      ? handleCreateNewUser()
                      : setNewUserStep(newUserStep + 1)
                  }
                  colorScheme={"blue"}
                  variant={"solid"}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : isFinalStep ? (
                    trans("createSite")
                  ) : (
                    trans("next")
                  )}
                </Button>
              </Stack>
            )}
          </Stack>
        </Flex>
      </Stack>
    </>
  );
}

export default NewUser;
