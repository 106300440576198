import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Box, ModalFooter, Button, Input } from "@chakra-ui/react"
import { Wheel, ShadeSlider, hsvaToHex, hexToHsva } from "@uiw/react-color"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";

const ColorPickerModal = ({ selectedColor, onClose, onSave }: { selectedColor?: string, onSave: (color: string) => void, onClose: () => void }) => {
  const { t } = useTranslation();

  const [hex, setHex] = useState(selectedColor || "#fff");
  const [customColor, setCustomColor] = useState(selectedColor || "#fff");
  const [hsva, setHsva] = useState({ h: 214, s: 43, v: 90, a: 1 });

  useEffect(() => {
    setHsva(hexToHsva(hex))
  }, [hex])
  const drawColorPicker = () => <>
    <Wheel
      style={{ margin: 'auto' }}
      color={hex}
      onChange={(color) => {
        setHex(color.hex)
        setCustomColor(color.hex)
      }}
    />
    <br />
    <ShadeSlider
      hsva={hsva}
      onChange={(newShade) => {
        setHsva({ ...hsva, ...newShade })
        setHex(
          hsvaToHex({ ...hsva, ...newShade })
        )
        setCustomColor(hsvaToHex({ ...hsva, ...newShade }))
      }}
    />
    <br />
    <Box width='fit-content' margin='auto' alignItems='center'>
      <Input width='fit-content' margin='auto' maxLength={7} onChange={(e) => {
        const value = e.target.value;
        const outputColor = value.charAt(0) === '#' ? value : '#' + value
        setCustomColor(outputColor);
        if (e.target.value.length >= 3) setHex(outputColor)
      }} value={customColor} />

      <Box style={{
        backgroundColor: hex,
        boxShadow: 'rgb(204, 204, 204) 1px 2px 4px',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        margin: '10px auto',

      }} />
      <h5 style={{
        textAlign: 'center',
        color: `${hex}`,
        fontWeight: 'bold',
        marginTop: '10px',
      }}>
        {hex}</h5>
    </Box>
  </>

  const handleSave = () => {
    onSave(hex)
    onClose()
  }

  return <CustomModal
    headerTitle={t('config.colorPicker')}
    customFooter={<>
      <Button variant='outline' colorScheme='pink' marginRight='10px' onClick={() => onClose()}>{t('generic.cancel')}</Button>
      <Button colorScheme='blue' onClick={() => handleSave()}>{t('generic.save')}</Button></>}
    onClose={() => onClose()} size='xl'>
    {drawColorPicker()}
  </CustomModal>
}

export default ColorPickerModal;