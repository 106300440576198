import { Image } from "@chakra-ui/react"
import CustomModal from "./CustomModal";

const ImageDetailModal = ({ image, onClose } : { image: string, onClose: () => void}) => {  
    return <CustomModal withoutFooter customFooter={<></>} onClose={() => onClose()} size='6xl'>
        <Image
          margin='auto'
          maxH={'80vh'}
          maxW={'80vw'}
          objectFit='cover'
          src={image}
          alt={''}
        />
  </CustomModal>
 }

 export default ImageDetailModal;