import { useState, useEffect } from "react";
import {
  Card,
  Box,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  SimpleGrid,
  FormControl,
  FormLabel,
  Image,
  Button,
  Textarea,
  useToast,
  useColorModeValue,
  Link,
  Spinner,
  Text,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Avatar,
  Switch,
} from "@chakra-ui/react";

import { editContent, getBlogData, getData } from "../services/api";
import { useAuth } from "../context/AuthContext";
import {
  BlogPostModal,
  CustomImage,
  CustomToolTip,
  ImageDetailModal,
  MediaCenter,
  PreviewButton,
} from "../components";
import { useTranslation } from "react-i18next";
import { convertObjectToArray, formatDate } from "../utils";

export default function Config() {
  const { t } = useTranslation();
  const trans = (key: string) => t(`blog.${key}`);

  // @ts-ignore
  const { user, logout } = useAuth();

  const blogDetails = {
    blogTitle: "",
    blogDescription: "",
  };

  const enableBlog = {
    enableBlog: false,
  };
  const emptyState = {
    ...blogDetails,
    ...enableBlog,
    blogCover: "",
  };

  const token = user?.stsTokenManager?.accessToken;
  const [userConfig, setUserConfig] = useState(emptyState);
  const [fullData, setFullData] = useState(null);
  const [blogData, setBlogData] = useState([]);
  const [imageKey, setImageKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPost, setSelectedPost] = useState<any>();
  const [showImageModal, setShowImageModal] = useState("");
  const [contentId, setContentId] = useState("");
  const toast = useToast();

  const getDataFromApi = async () => {
    setIsLoading(true);
    const data = await getData(token);
    if (data?.error === "Unauthorized") return logout();
    if (data?.userConfig) setUserConfig(data.userConfig);
    setFullData(data);
    await getBlogDataFromApi();
    setContentId(data.id);
    setIsLoading(false);
  };

  const getBlogDataFromApi = async () => {
    const blogResponse = await getBlogData(token);
    // @ts-ignore
    setBlogData(convertObjectToArray(blogResponse));
  };
  const sendChange = async () => {
    setIsLoading(true);
    const request = await editContent(
      token,
      {
        // @ts-ignore
        ...fullData,
        userConfig: userConfig,
      },
      contentId
    );

    if (!request?.error)
      toast({
        title: t("generic.sucess"),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    else
      toast({
        title: t("generic.errorUpdateDate"),
        description: t("generic.tryLater"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getDataFromApi();
  }, []);

  const handleField = (array: any[]) => {
    return Object.entries(array).map((e) => {
      // @ts-ignore
      const inputValue = userConfig[e[0]];
      const inputContent = () => {
        return (
          <Textarea
            placeholder=""
            value={inputValue}
            onChange={(value) =>
              setUserConfig({ ...userConfig, [e[0]]: value.target.value })
            }
          />
        );
      };
      return (
        <FormControl key={`${e[0]}_`}>
          <FormLabel>{trans(e[0])}</FormLabel>
          {inputContent()}
        </FormControl>
      );
    });
  };

  const handleImageChange = (url: string, description?: string) => {
    if (imageKey.includes("[")) {
      const key = imageKey.split("[")[0];
      const index = imageKey.split("[")[1].split("]")[0];
      // @ts-ignore
      let output = fullData[key];
      // @ts-ignore
      output[index].image = url;
      return setFullData({
        // @ts-ignore
        ...fullData,
        [key]: output,
      });
    } else
      setFullData({
        // @ts-ignore
        ...fullData,
        images: {
          // @ts-ignore
          ...fullData.images,
          [imageKey]: {
            description,
            url,
          },
        },
      });
    setImageKey("");
    setShowModal(false);
  };

  const openMediaCenter = (key: string, genericIndex?: number) => {
    const realIndex = genericIndex ? genericIndex - 1 : 0;
    setImageKey(`${key}${genericIndex ? `[${realIndex}]` : ""}`);
    setShowModal(true);
  };

  const drawImage = (imageUrl: string) => (
    <CustomImage
      image={imageUrl}
      onClick={(e: string) => setShowImageModal(e)}
    />
  );

  const drawImageInput = (key: string, label: string) => (
    <FormControl>
      <FormLabel>{label || key}</FormLabel>
      {/* @ts-ignore */}
      {drawImage(fullData?.images[key]?.url)}
      <Button onClick={() => openMediaCenter(key)}>
        {t("generic.changeImage")}
      </Button>
    </FormControl>
  );

  const bgColor = useColorModeValue("gray.200", "gray.900");
  return (
    <>
      {showModal && (
        <MediaCenter
          onClose={() => setShowModal(false)}
          selectImage={(e, x) => handleImageChange(e, x)}
        />
      )}
      {showImageModal && (
        <ImageDetailModal
          onClose={() => setShowImageModal("")}
          image={showImageModal}
        />
      )}
      {selectedPost && (
        <BlogPostModal
          token={token}
          post={selectedPost}
          onClose={() => setSelectedPost("")}
          onSave={() => getBlogDataFromApi()}
        />
      )}
      {isLoading && !fullData ? (
        <Box w="100%" textAlign="center" marginTop="100px">
          <Spinner margin="auto" />
        </Box>
      ) : (
        <Box
          w="100%"
          h="100%"
          bgPosition=""
          padding={4}
          backgroundColor={bgColor}
        >
          <SimpleGrid margin={4} columns={2} spacing={10}>
            <h1>{trans("pageTitle")}</h1>
            <Box w="fit-content" marginLeft="auto">
              <PreviewButton isBlog url={contentId} />
              <Button
                onClick={() => sendChange()}
                marginLeft="auto"
                w={"fit-content"}
                colorScheme="blue"
              >
                {isLoading ? <Spinner size="xs" /> : t("generic.save")}
              </Button>
            </Box>
          </SimpleGrid>
          <Card
            onClick={() =>
              setUserConfig({
                ...userConfig,
                enableBlog: !userConfig.enableBlog,
              })
            }
            cursor="pointer"
            margin={4}
          >
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    <SimpleGrid columns={2}>
                      <Box>
                        {trans("enableBlog")}
                        <Text
                          textTransform="capitalize"
                          fontWeight="medium"
                          marginTop={2}
                          fontSize="xs"
                          color="gray.400"
                        >
                          {trans("enableBlog_info")}
                        </Text>
                      </Box>
                      <Switch
                        isChecked={userConfig.enableBlog}
                        marginLeft="auto"
                        id="enable-blog"
                        pointerEvents='none'
                        size="lg"
                        onChange={(e: any) =>
                          setUserConfig({
                            ...userConfig,
                            enableBlog: e.target.checked,
                          })
                        }
                      />
                    </SimpleGrid>
                  </Heading>
                </Box>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {trans("blogCoverMainTitle")}
                    <CustomToolTip tip={trans("coverTip")} />
                  </Heading>
                </Box>
                <SimpleGrid columns={3} spacing={3}>
                  {/* @ts-ignore */}
                  {handleField(blogDetails)}
                  {drawImageInput("blogCover", trans("blogCover"))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box display="flex" alignItems="center">
                  <Heading size="xs" textTransform="uppercase">
                    {trans("blogPosts")}
                  </Heading>
                  <Button
                    marginLeft="auto"
                    onClick={() => setSelectedPost({ isNew: true })}
                    variant="solid"
                    colorScheme="blue"
                  >
                    {trans("newPost")}
                  </Button>
                </Box>

                <TableContainer>
                  <Table variant="simple">
                    {!blogData.length && (
                      <TableCaption>{trans("noPosts")}</TableCaption>
                    )}
                    <Thead>
                      <Tr>
                        <Th>{trans("post")}</Th>
                        <Th>{trans("slug")}</Th>
                        <Th>{trans("lastUpdate")}</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {blogData &&
                        [...blogData].reverse().map((e: any) => (
                          <Tr
                            key={e?.updatedAt}
                            _hover={{
                              backgroundColor: "gray.100",
                              cursor: "pointer",
                            }}
                          >
                            <Td
                              onClick={() => setSelectedPost(e)}
                              minH="74px"
                              display="flex"
                            >
                              {e?.postImageCover && (
                                <Avatar
                                  src={e?.postImageCover?.url}
                                  name={e?.postImageCover?.description}
                                />
                              )}
                              <Text margin="auto" marginLeft="14px">
                                {e?.title}
                              </Text>
                            </Td>
                            <Td onClick={() => setSelectedPost(e)}>
                              {e?.slug}
                            </Td>
                            <Td onClick={() => setSelectedPost(e)}>
                              {formatDate(e?.updatedAt)}
                            </Td>
                            <Td textAlign="right">
                              <Button
                                onClick={() =>
                                  setSelectedPost({ ...e, isDelete: true })
                                }
                                marginRight={4}
                                variant="solid"
                                colorScheme="red"
                              >
                                {t("generic.remove")}
                              </Button>
                              <Button
                                onClick={() => setSelectedPost(e)}
                                variant="solid"
                                colorScheme="blue"
                              >
                                {trans("editPost")}
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      )}
    </>
  );
}
