import {
  Button,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";

const ConfirmLogoutModal = ({
  onClose,
  onSave,
}: {
  onSave: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const trans = (key: string) => t(`header.${key}`);
  const confirmLogout = async () => {
    onSave();
    onClose();
  };

  return (
    <CustomModal
      headerTitle={trans("confirmLogout")}
      onClose={() => onClose()}
      size="xl"
      customFooter={
        <>
          <Button marginRight={4} onClick={onClose}>
            {t("generic.cancel")}
          </Button>
          <Button onClick={() => confirmLogout()} variant="solid" colorScheme="pink">
            {t("generic.confirm")}
          </Button></>
      }
    >

    </CustomModal>
  );
};

export default ConfirmLogoutModal;
