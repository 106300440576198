import { useEffect, useState } from "react";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  Link,
  Select,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  MoonIcon,
  SunIcon,
  LockIcon,
} from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useUtils } from "../context/UtilsContext";
import { useTranslation } from "react-i18next";
import { useNavigate, Link as NavLink, useLocation } from "react-router-dom";
import LogoCompact from "../assets/images/LogoCompact";
import ChangePasswordModal from "./ChangePasswordModal";
import ConfirmLogoutModal from "./ConfirmLogoutModal";

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  function handleChangeLanguage(language: string) {
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  }
  const trans = (key: string) => t(`header.${key}`);
  const language = localStorage.getItem("language") || "pt-BR";
  const availableLanguages = ["pt-BR", "en-US"];
  const location = useLocation();

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const { isOpen, onToggle } = useDisclosure();
  useEffect(() => {
    if (!user) navigate("/login");
  }, []);

  // @ts-ignore
  const { user, logout } = useAuth();

  // @ts-ignore
  const { userPhoto } = useUtils();
  return (
    <>
      {showConfirmLogoutModal && (
        <ConfirmLogoutModal
          onSave={() => logout()}
          onClose={() => setShowConfirmLogoutModal(false)}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          onClose={() => setShowChangePasswordModal(false)}
        />
      )}

      <Box>
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            {location.pathname !== "/newuser" && (
              <IconButton
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant={"ghost"}
                aria-label={trans("toggleNavigation")}
              />
            )}
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            <NavLink to="/">
              <LogoCompact
                width={130}
                height={55}
                color={useColorModeValue("#F63788", "white")}
              />
            </NavLink>
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <Box w="80px">
              <Select
                value={language}
                variant="flushed"
                onChange={(e) => handleChangeLanguage(e.target.value)}
              >
                {availableLanguages.map((lang) => (
                  <option key={lang}>{lang}</option>
                ))}
              </Select>
            </Box>
          </Stack>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar backgroundColor='gray.200' marginLeft={4} size={"md"} src={userPhoto} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setShowChangePasswordModal(true)}>
                  <LockIcon marginRight={4} /> {trans("changePassword")}
                </MenuItem>
                <MenuItem onClick={toggleColorMode}>
                  {colorMode === "light" ? (
                    <>
                      <MoonIcon marginRight={4} /> {trans("useDarkTheme")}
                    </>
                  ) : (
                    <>
                      <SunIcon marginRight={4} /> {trans("useLightTheme")}{" "}
                    </>
                  )}
                </MenuItem>
                <MenuDivider />
                <Button
                  as={"a"}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  width="90%"
                  display="-webkit-box"
                  bg={"pink.400"}
                  padding={4}
                  margin="auto"
                  onClick={() => setShowConfirmLogoutModal(true)}
                  _hover={{
                    bg: "pink.300",
                  }}
                >
                  {trans("logOut")}
                </Button>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </>
  );
};

export default Header;

const DesktopNav = () => {
  const linkColor = useColorModeValue("blue.600", "blue.200");
  const linkHoverColor = useColorModeValue("blue.800", "white");
  const linkActiveColor = useColorModeValue("pink.400", "pink.600");
  const location = useLocation();
  const { t } = useTranslation();
  const trans = (key: string) => t(`header.${key}`);
  if (location.pathname === "/newuser") return null;
  return (
    <Stack
      style={{ height: "fit-content", margin: "auto" }}
      direction={"row"}
      spacing={4}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <NavLink to={navItem?.href || ""}>
                <Link
                  as="a"
                  p={2}
                  fontSize="sm"
                  fontWeight={500}
                  color={
                    navItem.href === location.pathname
                      ? linkActiveColor
                      : linkColor
                  }
                  backgroundColor={
                    navItem.href === location.pathname
                      ? "blue.100"
                      : "transparent"
                  }
                  borderRadius="md"
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  {trans("nav." + navItem.label)}
                </Link>
              </NavLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={"white"}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <NavLink to={href || ""}>
      <Box
        height="fit-content"
        margin="auto"
        as="a"
        role={"group"}
        display={"block"}
        p={2}
        rounded={"md"}
        _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
      >
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text
              transition={"all .3s ease"}
              _groupHover={{ color: "pink.400" }}
              fontWeight={500}
            >
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
          <Flex
            transition={"all .3s ease"}
            transform={"translateX(-10px)"}
            opacity={0}
            _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
            justify={"flex-end"}
            align={"center"}
            flex={1}
          >
            <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Box>
    </NavLink>
  );
};

const MobileNav = () => {
  const { t } = useTranslation();
  const trans = (key: string) => t(`header.${key}`);
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={trans("nav." + navItem.label)} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  const { t } = useTranslation();
  const trans = (key: string) => t(`header.${key}`);

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <NavLink to={href || ""}>
        <Box
          py={2}
          as="a"
          justifyContent="space-between"
          alignItems="center"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Text
            fontWeight={600}
            color={useColorModeValue("gray.600", "gray.200")}
          >
            {trans("nav." + label)}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Box>
      </NavLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} onClick={() => null}>
                {trans("nav." + child.label)}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "dashboardContent",
    href: "/dashboard",
  },
  {
    label: "config",
    href: "/config",
  },
  {
    label: "blogManagement",
    href: "/blog",
  },
];

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
