import { useEffect, useState } from "react";
import { createBlogPost, deleteBlogPost, editBlogPost } from "../services/api";
import {

  ModalCloseButton,
  ModalFooter,
  Button,
  Input,
  Image,
  Textarea,
  ModalHeader,
  FormLabel,
  useToast,
  SimpleGrid,
  Box,
  Divider,
} from "@chakra-ui/react";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { MediaCenter } from "./MediaCenter";
import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";

const BlogPostModal = ({
  post,
  onClose,
  onSave,
  token,
}: {
  post?: any;
  onSave: () => void;
  onClose: () => void;
  token: string;
}) => {
  const { t } = useTranslation();
  const trans = (key: string) => t(`blog.${key}`);
  const toast = useToast();
  const isNewPost = post?.isNew;
  const isDelete = post?.isDelete;

  const [selectedPost, setSelectedPost] = useState(post);
  const [showMediaCenter, setShowMediaCenter] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(isDelete);

  useEffect(() => {
    if (isNewPost)
      setSelectedPost({
        title: "",
        slug: "",
        description: "",
        post: "",
        postImageCover: { url: "", description: "" },
      });
  }, []);
  const deletePost = async () => {
    // @ts-ignore
    const response = await deleteBlogPost(token, selectedPost.id);
    if (response.error) {
      toast({
        title: t("generic.error"),
        description: response.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: t("generic.sucess"),
        description: trans("postDeleted"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onSave();
    onClose();
  };

  const handleSave = async () => {
    if (isNewPost) {
      // @ts-ignore
      const response = await createBlogPost(token, selectedPost);
      if (response.error) {
        toast({
          title: t("generic.error"),
          description: response.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("generic.sucess"),
          description: trans("postCreated"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      // @ts-ignore
      const response = await editBlogPost(token, selectedPost, selectedPost.id);
      if (response.error) {
        toast({
          title: t("generic.error"),
          description: response.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: t("generic.sucess"),
          description: trans("postEdited"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    onSave();
    onClose();
  };

  const handleConfirmDelete = () => (
    <>
      <Button
        marginRight={4}
        onClick={() => (isDelete ? onClose() : setIsDeleteConfirm(false))}
      >
        {t("generic.cancel")}
      </Button>
      <Button onClick={() => deletePost()} variant="solid" colorScheme="red">
        {t("generic.confirm")}
      </Button>
    </>
  );

  const handleImageChange = (url: string, description?: string) => {
    setSelectedPost({ ...selectedPost, postImageCover: { url, description } });
    setShowMediaCenter(false);
  };

  const handleMainContentModal = () => (
    <>
      <SimpleGrid columns={2} spacing={4}>
        <Box>
          <FormLabel>{trans("post")}</FormLabel>
          <Input
            placeholder={trans("postPlaceHolder")}
            value={selectedPost?.title}
            onChange={(value) =>
              setSelectedPost({ ...selectedPost, title: value.target.value })
            }
          />
          <br />
          <br />
          <FormLabel>{"Slug"}</FormLabel>
          <Input
            placeholder={trans("slugPlaceHolder")}
            value={selectedPost?.slug}
            onChange={(value) =>
              setSelectedPost({
                ...selectedPost,
                slug: value.target.value.replace(/[^a-zA-Z0-9_ ]/g, '') // Remove special characters
                  .replace(/ /g, '_') // Replace spaces with underscores
                  .toLowerCase() // Convert to lowercase,
              })
            }
          />
          <br />
          <br />
        </Box>
        <Box>
          <FormLabel>{trans("description")}</FormLabel>
          <Textarea
            placeholder={trans("descriptionPlaceHolder")}
            value={selectedPost?.description}
            onChange={(value) =>
              setSelectedPost({
                ...selectedPost,
                description: value.target.value,
              })
            }
          />
          <br />
          <br />
          <FormLabel>{trans("postCover")}</FormLabel>
          <Box display="flex">
            {selectedPost?.postImageCover?.url && (
              <Image
                boxSize="100px"
                objectFit="cover"
                borderRadius="full"
                margin={4}
                boxShadow="lg"
                src={selectedPost.postImageCover?.url}
                alt={selectedPost.postImageCover?.description}
              />
            )}
            <Box display="block">
              {selectedPost?.postImageCover?.url && (
                <Button
                  marginRight={4}
                  colorScheme="gray"
                  onClick={() =>
                    setSelectedPost({ ...selectedPost, postImageCover: [] })
                  }
                >
                  {t("generic.remove")}
                </Button>
              )}
              <Button
                colorScheme="blue"
                onClick={() => setShowMediaCenter(true)}
              >
                {!selectedPost?.postImageCover?.url
                  ? t("generic.newImage")
                  : t("generic.changeImage")}
              </Button>
            </Box>
          </Box>
        </Box>
      </SimpleGrid>
      <br />
      <Divider />
      <br />
      <FormLabel>{trans("postContent")}</FormLabel>
      <MarkdownEditor
        enablePreview={true}
        visible
        enableScroll={false}
        minHeight="200px"
        value={selectedPost?.post}
        onChange={(value) => {
          setSelectedPost({ ...selectedPost, post: value });
        }}
      />
    </>
  );

  if (showMediaCenter)
    return (
      <MediaCenter
        onClose={() => setShowMediaCenter(false)}
        selectImage={(e, x) => handleImageChange(e, x)}
      />
    );

  return (
    <CustomModal
      onClose={() => onClose()}
      size="6xl"
      headerTitle={!isDeleteConfirm ? !isNewPost ? trans("editPost") : trans("newPost") : trans("confirmDelete")}
      customFooter={<>
        {!isDeleteConfirm ? (
          <>
            <Button
              onClick={() => setIsDeleteConfirm(true)}
              variant="solid"
              colorScheme="red"
            >
              {t("generic.remove")}
            </Button>
            <Button colorScheme="blue" marginLeft={4} onClick={() => handleSave()}>
              {t("generic.save")}
            </Button></>
        ) : handleConfirmDelete()}

      </>}
    >
      <> {!isDeleteConfirm && handleMainContentModal()}</>
    </CustomModal>
  );
};

export default BlogPostModal;
