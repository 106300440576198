import {
  Box,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";

const CustomImage = ({
  image,
  description,
  onClick,
}: {
  image?: string;
  description?: string;
  onClick: (image: string) => void;
}) => {
  const backgroundColor = useColorModeValue("gray.200", "gray.600");
  if (!image) return null;
  return (
    <Link display='block' width='fit-content' onClick={() => onClick(image)} >
      <Box boxSize='100px'
        backgroundSize='contain'
        backgroundPosition='center'
        backgroundRepeat='no-repeat'
        borderRadius='10px'
        marginBottom={2}
        backgroundColor={backgroundColor}
        backgroundImage={image}
      />
    </Link>
  );
};

export default CustomImage;
