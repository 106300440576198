import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = extendTheme({
  colors: {
    pink: {
      "50": "#FEE7F1",
      "100": "#FCBBD6",
      "200": "#FA8FBC",
      "300": "#F863A2",
      "400": "#F63788",
      "500": "#F40B6E",
      "600": "#C30958",
      "700": "#920742",
      "800": "#62042C",
      "900": "#310216",
    },
    blue: {
      "50": "#EAF9FB",
      "100": "#C3EFF4",
      "200": "#9CE4EC",
      "300": "#76D9E5",
      "400": "#4FCFDE",
      "500": "#29C4D6",
      "600": "#209DAC",
      "700": "#187681",
      "800": "#104F56",
      "900": "#08272B",
    },
  },
});

root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
