// UtilsContext.js
import React, { createContext, useContext, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export type UtilsContextType = {
  triggerToast: (
    title: string,
    description?: string,
    success?: boolean
  ) => void;
  setUserImage: (image: string) => void;
  userPhoto: string;
  copyToClipboard: (text: string) => void;
};

const UtilsContext = createContext<UtilsContextType | null>(null);

// @ts-ignore
export const UtilsProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [userPhoto, setUserPhoto] = useState<string>("");
  const triggerToast = (
    title: string,
    description?: string,
    success?: boolean
  ) => {
    toast({
      title,
      description: description || "",
      status: success ? "success" : "error",
      duration: 4000,
      isClosable: true,
    });
  };

  const setUserImage = (image: string) => setUserPhoto(image);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        triggerToast(t("generic.copiedToClipboard"), text, true);
      },
      function (err) {
        triggerToast(
          t("generic.errorCopyToClipboard", { err: err }),
          text,
          false
        );
      }
    );
  };

  return (
    //@ts-ignore
    <UtilsContext.Provider
      value={{ triggerToast, setUserImage, userPhoto, copyToClipboard }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export const useUtils = () => {
  return useContext(UtilsContext);
};

export const handleCRPInput = (e: string) =>
  e.replace(/^(\d{2})(\d*)$/, "$1/$2");
