import { Box, Text } from "@chakra-ui/react";
import CustomModal from "./CustomModal";
import { useTranslation } from "react-i18next";

const DnsDetailModal = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const texts = {
    title: t("dnsModal.title"),
    explanation: t("dnsModal.explanation"),
    dnsRequired: t("dnsModal.dnsRequired"),
    dnsFinish: t("dnsModal.dnsFinish"),
  };
  return (
    <CustomModal
      headerTitle={texts.title}
      withoutFooter
      customFooter={<></>}
      onClose={() => onClose()}
      size="4xl"
    >
      <Box >
      <br />
      <Text>{texts.explanation}</Text>
      <br />
      <Text whiteSpace='break-spaces' size='lg' as="b">{texts.dnsRequired}</Text>
      <br />
      <br />
      <Text>{texts.dnsFinish}</Text>
      <br />
      </Box>
    </CustomModal>
  );
};

export default DnsDetailModal;
