import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";
import { Box, Select, Image } from "@chakra-ui/react";
import { useState } from "react";

const TemplateOptionsModal = ({
  onClose,
  onSave,
  selectedTemplate,
}: {
  onSave: (selectedTemplate: string) => void;
  onClose: () => void;
  selectedTemplate: string;
}) => {
  const { t } = useTranslation();
  const [template, setTemplate] = useState(selectedTemplate);
  const trans = (key: string) => t(`config.${key}`);

  const imageUrls = {
    default:
      "https://res.cloudinary.com/dmasaq9xd/image/upload/v1700626226/hvggmy2ynoetyfmsfp48.png",
    backgroundCenter:
      "https://res.cloudinary.com/dmasaq9xd/image/upload/v1700626227/cbvwwohn95isflgegfaq.png",
    defaultRightWithCurves:
      "https://res.cloudinary.com/dmasaq9xd/image/upload/v1700626226/hhhmncevclpz40zjl5kx.png",
  };

  return (
    <CustomModal 
      onClose={() => onClose()}
      onSave={() => onSave(template)}
      headerTitle={trans("templateModalTitle")}
      size="2xl"
    >
      <Box margin={4}>
        <Select
          value={template}
          onChange={(value) => setTemplate(value.target.value)}>
          <option value="default">{trans("template1")} </option>
          <option value="backgroundCenter">{trans("template2")}</option>
          <option value="defaultRightWithCurves">{trans("template3")}</option>
        </Select>
        <br />
        <Image
          maxH={"80vh"}
          maxW={"80vw"}
          width={"100%"}
          height={"100%"}
          objectFit="cover"
          // @ts-ignore
          src={imageUrls[template]}
        />
      </Box>
    </CustomModal>
  );
};

export default TemplateOptionsModal;
