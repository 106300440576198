// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8qOYNu9fRHccsWhhZuJiavouLCn6mdPw",
  authDomain: "api-psico-3210b.firebaseapp.com",
  databaseURL: "https://api-psico-3210b-default-rtdb.firebaseio.com",
  projectId: "api-psico-3210b",
  storageBucket: "api-psico-3210b.appspot.com",
  messagingSenderId: "289945372592",
  appId: "1:289945372592:web:79beaea691754ff21bb875",
  measurementId: "G-JRGDHRJN57"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
