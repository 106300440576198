import "./App.css";
import AppContainer from "./components/AppContainer";
import { AuthProvider } from "./context/AuthContext";
import { UtilsProvider } from "./context/UtilsContext";
import MainNavigator from "./MainNavigator";

import "./i18n";
import { CSSDarkGlobal } from "./components";

function App() {
  return (
    // @ts-ignore
    <AuthProvider>
      <CSSDarkGlobal />
      {/* @ts-ignore */}
      <UtilsProvider>
        <AppContainer>
          <MainNavigator />
        </AppContainer>
      </UtilsProvider>
    </AuthProvider>
  );
}

export default App;
