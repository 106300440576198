import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Stack,
  Text,
  Image,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";

import { deleteImage, getImages, uploadImage } from "../services/api";
import EditableInput from "./EditableInput";
import { useTranslation } from "react-i18next";
import CustomModal from "./CustomModal";
export const MediaCenter = ({
  selectImage,
  onClose,
}: {
  selectImage: (image: string, description?: string) => void;
  onClose: () => void;
}) => {
  const { user } = useAuth() || {};
  const [isLoading, setIsLoading] = useState(true);
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [newImageDescription, setNewImageDescription] = useState("");
  const { t } = useTranslation();
  const trans = (key: string) => t(`mediaCenter.${key}`);

  const token = (user as any)?.stsTokenManager?.accessToken;
  const handleUploadImage = async () => {
    setIsLoading(true);
    const newImages = await uploadImage(token, file, newImageDescription);
    setFile(null);
    setNewImageDescription("");
    setImages(newImages);
    setIsUploadImage(false);
    setTriggerUpdate(triggerUpdate + 1);
    setIsLoading(false);
  };

  const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];
  const handleClean = () => {
    setFile(null);
    setNewImageDescription("");
  };
  function DragDrop() {
    const handleChange = (file: any) => {
      setFile(file);
    };
    return (
      <>
        <FormControl>
          <FileUploader
            style={{ maxWidth: "100%" }}
            label={trans("dragAndDrop")}
            handleChange={handleChange}
            name="file"
            types={fileTypes}
          />
        </FormControl>

        {file && (
          <>
            <div>
              {/* @ts-ignore */}
              {file.name}
            </div>
            <div>
              <Button marginTop={4} onClick={() => handleClean()}>
                {trans("clear")}
              </Button>
            </div>
            <Divider margin="20px 0" />
          </>
        )}
        <br />
        <FormControl>
          <FormLabel>{trans("imageDescription")}</FormLabel>
          <Input
            placeholder=""
            value={newImageDescription}
            onChange={(value) => setNewImageDescription(value.target.value)}
          />
        </FormControl>
      </>
    );
  }

  const handleDeleteImage = async (id: string) => {
    setIsLoading(true);
    const outputImages = await deleteImage(token, id);
    handleSetImages(outputImages);
    setIsLoading(false);
  };

  const transformObjectToArray = (obj: any) => {
    return Object.values(obj);
  };

  const handleSetImages = (data: any) => {
    const images = transformObjectToArray(data);
    // @ts-ignore
    setImages(images);
  };

  const getImagesData = async () => {
    setIsLoading(true);
    const data = await getImages(token);
    const images = transformObjectToArray(data);
    setIsLoading(false);
    // @ts-ignore
    setImages(images.reverse());
  };

  useEffect(() => {
    getImagesData();
  }, [isUploadImage]);

  const handleSelect = (image: string, description?: string) => {
    selectImage(image, description);
    onClose();
  };

  const drawSelectImage = () => (
    <SimpleGrid columns={4} spacing={10}>
      {images.length
        ? images.map(
            (image: { imageURL: string; id: string; description: string }) => (
              <>
                <Card maxW="sm">
                  <CardBody>
                    <Image
                      src={image.imageURL}
                      alt={image.description}
                      borderRadius="lg"
                      maxHeight="200px"
                    />
                    <Stack mt="6" spacing="3">
                      <Text as="sub">{trans("imageDescription")}</Text>
                      <EditableInput image={image} />
                    </Stack>
                  </CardBody>
                  <Divider />
                  <CardFooter>
                    <ButtonGroup spacing="3">
                      <Box>
                        <Button
                          onClick={() => handleDeleteImage(image.id)}
                          variant="ghost"
                          colorScheme="red"
                        >
                          {trans("delete")}
                        </Button>
                        <Button
                          marginLeft="auto"
                          onClick={() =>
                            handleSelect(image.imageURL, image.description)
                          }
                          variant="solid"
                          colorScheme="blue"
                        >
                          {trans("add")}
                        </Button>
                      </Box>
                    </ButtonGroup>
                  </CardFooter>
                </Card>
              </>
            )
          )
        : trans("noImages")}
    </SimpleGrid>
  );

  return (
    <>
      <CustomModal
        onClose={onClose}
        size="6xl"
        headerTitle={trans("selectImage")}
        customFooter={
          <>
            {!isUploadImage ? (
              <>
                <Button onClick={onClose}>{t("generic.close")}</Button>
                <Button
                  colorScheme="blue"
                  onClick={() => setIsUploadImage(!isUploadImage)}>
                  {trans("addNewImage")}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => setIsUploadImage(!isUploadImage)}>
                  {t("generic.cancel")}
                </Button>
                {file && (
                  <Button
                    colorScheme="blue"
                    onClick={() => file && handleUploadImage()}
                  >
                    {trans("upload")}
                  </Button>
                )}
              </>
            )}
          </>
        }
      >
        <Box margin='30px auto' width='fit-content'>
          {!isUploadImage ? (
            <>{isLoading ? <Spinner margin='auto' /> : drawSelectImage()}</>
          ) : (
            <div>{isLoading ? <Spinner margin='auto' /> : DragDrop()}</div>
          )}
        </Box>
      </CustomModal>
    </>
  );
};
