import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  ModalHeader,
  useColorModeValue,
  ModalBody,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

const CustomModal = ({
  onClose,
  onSave,
  children,
  headerTitle,
  size,
  customFooter,
  withoutFooter,
}: {
  onSave?: () => void;
  onClose: () => void;
  withoutFooter?: boolean;
  headerTitle?: string;
  children: React.ReactNode;
  customFooter?: React.ReactNode;
  size?: string;
}) => {
  const { t } = useTranslation();
  const confirmAction = async () => {
    onSave && onSave();
    onClose();
  };

  const contrastBg = useColorModeValue("gray.100", "gray.800");

  const handleConfirmLogout = () => (
    <>
      {headerTitle && <ModalHeader borderRadius={'10px 10px 0 0'} backgroundColor={contrastBg}>{headerTitle}</ModalHeader>}
      <ModalCloseButton />
      <ModalBody>{children}</ModalBody>
      {!withoutFooter && <ModalFooter borderRadius={'0 0 10px 10px'} backgroundColor={contrastBg}>
        {customFooter ? (
          customFooter
        ) : (
          <>
            <Button onClick={onClose}>{t("generic.close")}</Button>
            <Button colorScheme="blue" onClick={confirmAction}>
              {t("generic.save")}
            </Button>
          </>
        )}
      </ModalFooter>}
    </>
  );

  return (
    <Modal
      scrollBehavior="inside"
      onClose={() => onClose()}
      isOpen={true}
      size={size || "xl"}
      isCentered
    >
      <ModalOverlay />
      <ModalHeader backgroundColor={contrastBg}>{headerTitle}</ModalHeader>
      <ModalContent borderRadius={4}>{handleConfirmLogout()}</ModalContent>
    </Modal>
  );
};

export default CustomModal;
