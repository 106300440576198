import { Button } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    url: string;
    isBlog?: boolean;
    fullWidth?: boolean;
}

const PreviewButton: React.FC<Props> = ({ url, isBlog, fullWidth }) => {
    const { t } = useTranslation()
    const base = process.env.REACT_APP_DEMO_URL || ''
    const showBlog = isBlog ? 'blog/' : ''
    const output = `${base}/${showBlog}?contentId=${url}`
    return (
        <Button marginRight={4} onClick={() => window.open(output, '_blank')} marginLeft='auto' w={fullWidth ? '100%' : 'fit-content'} colorScheme='blue' variant='outline'>{t('generic.seePreview')}</Button>
    );
};

export default PreviewButton;
