import { useState, useEffect } from "react";
import {
  Card,
  Box,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Textarea,
  useToast,
  useColorModeValue,
  Spinner,
  InputGroup,
  InputLeftElement,
  Select,
  Link,
} from "@chakra-ui/react";
import FontPicker from "font-picker-react";
import { Option } from "chakra-ui-simple-autocomplete";
import {
  LiaFacebookF,
  LiaInstagram,
  LiaLinkedinIn,
  LiaTwitter,
  LiaUser,
  LiaWhatsapp,
  LiaYoutube,
  LiaLinkSolid,
} from "react-icons/lia";
import { MultiSelect } from "react-multi-select-component";

import { editContent, getData } from "../services/api";
import { useAuth } from "../context/AuthContext";
import {
  ColorPickerModal,
  CustomImage,
  CustomLink,
  CustomToolTip,
  DnsDetailModal,
  ImageDetailModal,
  MediaCenter,
  PreviewButton,
  TemplateOptionsModal,
} from "../components";
import { useTranslation } from "react-i18next";
import { isColorDark, providedUrlStringIsValid } from "../utils";

export default function Config() {
  const { t } = useTranslation();
  const trans = (key: string) => t(`config.${key}`);

  // @ts-ignore
  const { user, logout } = useAuth();
  const colors = {
    primaryColor: "",
    secondaryColor: "",
  };
  const fonts = {
    primaryFont: "",
    secondaryFont: "",
  };

  const webStyles = {
    ...colors,
    ...fonts,
    template: "",
  };

  const handleSocialMediaIcon = (key: string) => {
    const iconsStyleProps = {
      size: "20px",
    };
    switch (key) {
      case "socialMediaFacebook":
        return <LiaFacebookF {...iconsStyleProps} />;
      case "socialMediaInstagram":
        return <LiaInstagram {...iconsStyleProps} />;
      case "socialMediaTwitter":
        return <LiaTwitter {...iconsStyleProps} />;
      case "socialMediaLinkedin":
        return <LiaLinkedinIn {...iconsStyleProps} />;
      case "socialMediaYoutube":
        return <LiaYoutube {...iconsStyleProps} />;
      case "socialMediaWhatsapp":
        return <LiaWhatsapp {...iconsStyleProps} />;
      case "userDomainUrl":
        return <LiaLinkSolid {...iconsStyleProps} />;
      case "userName":
        return <LiaUser {...iconsStyleProps} />;
      default:
        return <LiaFacebookF {...iconsStyleProps} />;
    }
  };

  const handleSocialMediaPlaceHolder = (key: string) => {
    if (key === "socialMediaWhatsapp") return trans("whatsappNumber");
    return `@${t("config.yourSocialMediaProfile", {
      social: key.replace("socialMedia", ""),
    })}`;
  };

  const list = [
    "anxietyDisorders",
    "moodDisorders",
    "eatingDisorders",
    "sleepDisorders",
    "personalityDisorders",
    "developmentalDisorders",
    "psychosomaticDisorders",
    "stressRelatedDisorders",
    "impulseControlProblems",
    "sexualDisorders",
    "psychoticDisorders",
    "adjustmentProblems",
    "postTraumaticStressDisorder",
    "attentionDeficitHyperactivityDisorder",
    "burnoutSyndrome",
    "adjustmentDisorder",
    "intermittentExplosiveDisorder",
    "kleptomania",
    "pyromania",
    "erectileDysfunction",
    "hypoactiveSexualDesireDisorder",
    "vaginismus",
    "schizophreniformDisorder",
    "schizoaffectiveDisorder",
    "delusionalDisorder",
    "adjustmentDifficultiesAfterSignificantLifeEvents",
    "pathologicalGambling",
    "compulsiveBuyerDisorder",
  ];

  const options = list.map((item) => ({
    value: t(`disorders.${item}`).toLowerCase().replace(/ /g, "-"),
    label: t(`disorders.${item}`),
  }));

  const socialMedia = {
    socialMediaFacebook: "",
    socialMediaInstagram: "",
    socialMediaTwitter: "",
    socialMediaLinkedin: "",
    socialMediaYoutube: "",
  };
  const userDetails = {
    userName: "",
    workFocus: "",
    socialMediaWhatsapp: "",
    address: "",
    ProfessionalId: "",
  };

  const webDetails = {
    googleAnalyticsId: "",
    userDomainUrl: "",
  };

  const emptyState = {
    ...webStyles,
    ...userDetails,
    logo: "",
    ...webDetails,
    ...socialMedia,
  };

  const token = user?.stsTokenManager?.accessToken;
  const [userConfig, setUserConfig] = useState(emptyState);
  const [fullData, setFullData] = useState(null);
  const [imageKey, setImageKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDnsModal, setShowDnsModal] = useState(false);
  const [result, setResult] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState("");
  const [showColorPickerModal, setShowColorPickerModal] = useState("");
  const [showTemplateOptionsModal, setShowTemplateOptionsModal] =
    useState(false);
  const [contentId, setContentId] = useState("");
  const toast = useToast();

  const getDataFromApi = async () => {
    setIsLoading(true);
    const data = await getData(token);
    if (data?.error === "Unauthorized") return logout();
    if (data?.userConfig) setUserConfig(data.userConfig);
    setFullData(data);
    setContentId(data.id);
    let workFocus = data?.userConfig?.workFocus;
    if (workFocus && workFocus.startsWith(",")) {
      workFocus = workFocus.replace(/^,/, "");
    }
    const parsedWorkFocus = workFocus ? JSON.parse(workFocus) : [];
    setResult(parsedWorkFocus);
    setIsLoading(false);
  };

  useEffect(() => {
    if (result)
      setUserConfig({ ...userConfig, workFocus: JSON.stringify(result) });
  }, [result]);

  const sendChange = async () => {
    setIsLoading(true);
    const request = await editContent(
      token,
      {
        // @ts-ignore
        ...fullData,
        userConfig: userConfig,
      },
      contentId
    );

    if (!request?.error)
      toast({
        title: t("generic.sucess"),
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    else
      toast({
        title: t("generic.errorUpdateDate"),
        description: t("generic.tryLater"),
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getDataFromApi();
  }, []);

  const handleNewField = (value: string) => ({
    label: value,
    value: value.toUpperCase(),
  });

  const handleField = (array: any[], specialElements?: any[]) => {
    const showAdditionalInfo = (key: string) =>
      [
        "socialMediaWhatsapp",
        "address",
        "workFocus",
        "primaryColor",
        "secondaryColor",
        "enableBlog",
      ].includes(key);
    return Object.entries(array).map((e) => {
      // @ts-ignore
      const inputValue = userConfig[e[0]];
      const inputContent = () => {
        // Address Input
        if (e[0].includes("address")) {
          return (
            <>
              <Input
                marginBottom={2}
                value={inputValue}
                onChange={(value) =>
                  setUserConfig({
                    ...userConfig,
                    [e[0]]: value.target.value,
                  })
                }
                placeholder={trans("addressInputPlaceholder")}
              />
              {inputValue && (
                <>
                  <Box overflow="hidden">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          width="600"
                          height="500"
                          id="gmap_canvas"
                          src={`https://maps.google.com/maps?q=${inputValue}&output=embed`}
                          scrolling="no"
                        ></iframe>
                      </div>
                    </div>
                  </Box>
                  <Button
                    marginTop={4}
                    onClick={() => setUserConfig({ ...userConfig, [e[0]]: "" })}
                  >
                    {t("generic.clear")}
                  </Button>
                </>
              )}
            </>
          );
        }
        if (e[0].includes("Color"))
          return (
            <>
              {inputValue && (
                <Box
                  onClick={() => setShowColorPickerModal(e[0])}
                  borderRadius="10px"
                  marginBottom="10px"
                  textAlign="center"
                  padding={4}
                  backgroundColor={
                    isColorDark(inputValue) ? "gray.200" : "gray.800"
                  }
                >
                  <h5 style={{ color: `${inputValue}` }}>{inputValue}</h5>

                  <Box
                    style={{
                      margin: "auto",
                      borderRadius: "50%",
                      backgroundColor: `${inputValue}`,
                      width: "20px",
                      height: "20px",
                    }}
                  ></Box>
                </Box>
              )}
              <Button onClick={() => setShowColorPickerModal(e[0])}>
                {inputValue ? trans("changeColor") : trans("addColor")}
              </Button>
            </>
          );
        // Font Picker
        if (e[0].includes("Font"))
          return (
            <>
              <FontPicker
                pickerId={e[0]}
                sort="popularity"
                apiKey="AIzaSyDulfr1gnytb32NkR9S6kKCdCgqPUq6lrs"
                activeFontFamily={inputValue || "Open Sans"}
                onChange={(nextFont) =>
                  setUserConfig({ ...userConfig, [e[0]]: nextFont.family })
                }
              />
              <p
                style={{
                  fontFamily: inputValue,
                  marginTop: "10px",
                }}
                className="apply-font"
              >
                {trans("howFontWillBeApplied")}: {trans(`${e[0]}Use`)}
              </p>
            </>
          );
        // Selector Input with MultiSelect
        if (e[0].includes("workFocus"))
          return (
            <>
              <MultiSelect
                options={options}
                onCreateOption={handleNewField}
                isCreatable
                value={result}
                onChange={(e: any) => {
                  setResult(e);
                }}
                labelledBy="Select"
              />
            </>
          );
        // Selector Input
        if (e[0].includes("template"))
          return (
            <>
              <Select
                value={inputValue}
                onChange={(value) =>
                  setUserConfig({ ...userConfig, [e[0]]: value.target.value })
                }
                placeholder={trans("selectOption")}
              >
                <option value="default">{trans("template1")} </option>
                <option value="backgroundCenter">{trans("template2")}</option>
                <option value="defaultRightWithCurves">
                  {trans("template3")}
                </option>
              </Select>
              <Link onClick={() => setShowTemplateOptionsModal(true)}>
                <Text color="blue.500">{trans("samples")}</Text>
              </Link>
            </>
          );
        // Regular input with Icon
        if (["socialMedia", "userName"].some((el) => e[0].includes(el)))
          return (
            <>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  {handleSocialMediaIcon(e[0])}
                </InputLeftElement>
                <Input
                  type="tel"
                  value={inputValue}
                  onChange={(value) =>
                    setUserConfig({ ...userConfig, [e[0]]: value.target.value })
                  }
                  placeholder={handleSocialMediaPlaceHolder(e[0])}
                />
              </InputGroup>
            </>
          );
        if (["userDomainUrl"].some((el) => e[0].includes(el)))
          return (
            <>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  {handleSocialMediaIcon(e[0])}
                </InputLeftElement>
                <Input
                  value={inputValue}
                  onChange={(value) =>
                    setUserConfig({ ...userConfig, [e[0]]: value.target.value })
                  }
                  errorBorderColor="red.300"
                  isInvalid={!providedUrlStringIsValid(inputValue)}
                  placeholder={trans("urlPlaceholder")}
                />
              </InputGroup>
              {!providedUrlStringIsValid(inputValue) && (
                <Text fontSize="xs" color="red">
                  {trans("urlInvalid")}
                </Text>
              )}

              {specialElements &&
                specialElements[e[0] as unknown as number] && (
                  <>{specialElements[e[0] as unknown as number]}</>
                )}
            </>
          );
        return (
          <>
            <Textarea
              placeholder=""
              value={inputValue}
              onChange={(value) =>
                setUserConfig({ ...userConfig, [e[0]]: value.target.value })
              }
            />
          </>
        );
      };
      return (
        <FormControl key={`${e[0]}_`}>
          <FormLabel>{trans(e[0])}</FormLabel>
          {inputContent()}
          {showAdditionalInfo(e[0]) && (
            <Text size="xxs" color="gray.500" marginTop="10px">
              {" "}
              {trans(`${e[0]}_info`)}{" "}
            </Text>
          )}
        </FormControl>
      );
    });
  };

  const handleImageChange = (url: string, description?: string) => {
    if (imageKey.includes("[")) {
      const key = imageKey.split("[")[0];
      const index = imageKey.split("[")[1].split("]")[0];
      // @ts-ignore
      let output = fullData[key];
      // @ts-ignore
      output[index].image = url;
      return setFullData({
        // @ts-ignore
        ...fullData,
        [key]: output,
      });
    } else
      setFullData({
        // @ts-ignore
        ...fullData,
        images: {
          // @ts-ignore
          ...fullData.images,
          [imageKey]: {
            description,
            url,
          },
        },
      });
    setImageKey("");
    setShowModal(false);
  };

  const openMediaCenter = (key: string, genericIndex?: number) => {
    const realIndex = genericIndex ? genericIndex - 1 : 0;
    setImageKey(`${key}${genericIndex ? `[${realIndex}]` : ""}`);
    setShowModal(true);
  };

  const drawImage = (imageUrl: string) => (
    <CustomImage
      image={imageUrl}
      onClick={(e: string) => setShowImageModal(e)}
    />
  );

  const drawImageInput = (key: string, label: string) => (
    <FormControl>
      <FormLabel>{label || key}</FormLabel>
      {/* @ts-ignore */}
      {fullData?.images[key]?.url && drawImage(fullData?.images[key]?.url)}
      <Button onClick={() => openMediaCenter(key)}>
        {t("generic.changeImage")}
      </Button>
    </FormControl>
  );

  const bgColor = useColorModeValue("gray.200", "gray.900");
  return (
    <>
      {showModal && (
        <MediaCenter
          onClose={() => setShowModal(false)}
          selectImage={(e, x) => handleImageChange(e, x)}
        />
      )}
      {showImageModal && (
        <ImageDetailModal
          onClose={() => setShowImageModal("")}
          image={showImageModal}
        />
      )}
      {showColorPickerModal && (
        <ColorPickerModal
          onClose={() => setShowColorPickerModal("")}
          onSave={(e: any) =>
            setUserConfig({ ...userConfig, [showColorPickerModal]: e })
          }
          // @ts-ignore
          selectedColor={userConfig[showColorPickerModal]}
        />
      )}
      {showTemplateOptionsModal && (
        <TemplateOptionsModal
          onClose={() => setShowTemplateOptionsModal(false)}
          onSave={(e: string) => setUserConfig({ ...userConfig, template: e })}
          selectedTemplate={userConfig.template}
        />
      )}
      {showDnsModal && (
        <DnsDetailModal onClose={() => setShowDnsModal(false)} />
      )}
      {isLoading && !fullData ? (
        <Box w="100%" textAlign="center" marginTop="100px">
          <Spinner margin="auto" />
        </Box>
      ) : (
        <Box
          w="100%"
          h="100%"
          bgPosition=""
          padding={4}
          backgroundColor={bgColor}
        >
          <SimpleGrid margin={4} columns={2} spacing={10}>
            <h1>{trans("pageTitle")}</h1>
            <Box w="fit-content" marginLeft="auto">
              <PreviewButton url={contentId} />
              <Button
                onClick={() => sendChange()}
                marginLeft="auto"
                w={"fit-content"}
                colorScheme="blue"
              >
                {isLoading ? <Spinner size="xs" /> : t("generic.save")}
              </Button>
            </Box>
          </SimpleGrid>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {trans("visualStyles")}
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(webStyles)}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {trans("userDetails")}
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(userDetails)}
                  {drawImageInput("logo", trans("logoImage"))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {trans("socialMedia")}
                    <CustomToolTip tip={trans("socialMediaTip")} />
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(socialMedia)}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    {trans("technicalDetails")}
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(webDetails, {
                    userDomainUrl: (
                      <CustomLink
                        onClick={() => setShowDnsModal(true)}
                        text={trans("dnsInstructions")}
                      />
                    ),
                    validations: () => true,
                    errorMessage: trans("dnsInstructions"),
                  })}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
        </Box>
      )}
    </>
  );
}
