import { BrowserRouter, Route, Routes } from "react-router-dom"

import Login from './page/Login';
import Dashboard from './page/Dashboard';
import Blog from './page/Blog';
import Config from './page/Config';
import NewUser from './page/NewUser';
import { useAuth } from "./context/AuthContext";
import { Header } from "./components";

const MainNavigator = () => {
    // @ts-ignore
    const { user } = useAuth();
    return (
        <BrowserRouter>              
            {!user ?
                <Routes>
                    <Route path="/" index element={<Login />} />
                    <Route path="*" element={<Login />} />
                </Routes> :
                <>
                    <Header />
                    <Routes>
                        <Route path="/" index element={<Dashboard />} />
                        <Route path="/config" element={<Config />} />
                        <Route path="/newuser" element={<NewUser />} />
                        <Route path="/admin" element={<Dashboard />} />
                        <Route path="/blog" element={<Blog />} />    
                        <Route path="*" element={<Dashboard />} />
                    </Routes>
                </>
            }

        </BrowserRouter>
    )
}
export default MainNavigator;