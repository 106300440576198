import { Text, Link } from "@chakra-ui/react";

const CustomLink = ({
  text,
  onClick,
}: {
  text?: string;
  onClick: () => void;
}) => {
  return (
    <Link onClick={() => onClick()}>
      <Text color="blue.500">{text}</Text>
    </Link>
  );
};

export default CustomLink;
