import { useColorModeValue } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import React from "react";

const CSSDarkGlobal: React.FC = () => {
  const isDarkMode = useColorModeValue(false, true);

  return (
    <>
      {isDarkMode && (
        <Global
          styles={css`
            .dropdown-button {
              background-color: #222 !important;
            }
            div[id^="font-picker"] ul {
              background-color: #333 !important;
            }
            div[id^="font-picker"] ul li button.active-font {
              color: #000;
            }
            div[id^="font-picker"] ul li button:hover,
            div[id^="font-picker"] ul li button:focus {
              background-color: #777;
            }
            .rmsc .dropdown-heading {
              background-color: #222 !important;
            }
            .select-item {
              background-color: #222 !important;
            }
            .select-panel {
              background-color: #222 !important;
            }
            .rmsc .search input:focus {
              background-color: #333 !important;
            }
            div[id^="font-picker"] .dropdown-icon.finished::before {
              border-top: 6px solid #fff;
            }
          `}
        />
      )}
    </>
  );
};

export default CSSDarkGlobal;
