import axios from 'axios';


const url = process.env.REACT_APP_API_URL || ''


const instance = axios.create({
    baseURL: url,
  });

  // Add a request interceptor
instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );


  export const getData = async (token: string) => {
    try {
        const { data } = await instance.get('');
        return data;
    } catch (error) {
        return error
    }
}

export const getBlogData = async (token: string) => {
    try {
        const { data } = await instance.get('/blog');
        return data;
    } catch (error) {
        return 'error'
    }
}

export const editBlogPost = async (token: string, payout: number, blogPostId: string) => {
    try {
        const { data } = await instance.put(`/blog/${blogPostId}`, JSON.stringify(payout), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return { error: 'Error editing content' }
    }
}

export const deleteBlogPost = async (token: string, blogPostId: string) => {
    try {
        const { data } = await instance.delete(`/blog/${blogPostId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return { error: 'Error editing content' }
    }
}

export const createBlogPost = async (token: string, payout: number) => {
    try {
        const { data } = await instance.post('/blog', JSON.stringify(payout), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return { error: 'Error editing content' }
    }
}

export const finishCreateUser = async (token: string, payout: any) => {
    try {
        const { data } = await instance.post('/signup', JSON.stringify(payout), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return { error: 'Error editing content' }
    }
}

export const getImages = async (token: string) => {
    try {
        const { data } = await instance.get('/images', {
            headers: {
                Authorization: token,
            }
        });
        return data;
    } catch (error) {
        // @ts-ignore
        return error.message
    }
}

export const uploadImage = async (token: string, file: any, description: string) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('description', description);
    try {
        const { data } = await instance.post('/images', formData, {
            headers: {
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return 'error'
    }
}

export const editImage = async (token: string, description: string, id: string) => {
    try {
        const { data } = await instance.put(`/images/${id}`, JSON.stringify({ description }), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return 'error'
    }
}

export const editContent = async (token: string, payout: number, contentId: string) => {
    try {
        const { data } = await instance.put(`/${contentId}`, JSON.stringify(payout), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return { error: 'Error editing content' }
    }
}

export const deleteImage = async (token: string, id: string) => {
    try {
        const { data } = await instance.delete(`/images/${id}`, {
            headers: {
                'Authorization': token,
            }
        });
        return data;
    } catch (error) {
        return 'error'
    }
}