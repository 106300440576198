import React, {  useEffect } from 'react';

import { useTranslation } from 'react-i18next'
import { Box, Select } from '@chakra-ui/react';

const AppContainer = ({ children }: { children: any }) => {
  const { i18n } = useTranslation()

  function handleChangeLanguage(language: string) {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  const language = localStorage.getItem('language') || 'pt-BR';
  const availableLanguages = ['pt-BR', 'en-US']

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  return <>
    {children}
  </>;
};

export default AppContainer;