import { Tooltip } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

const CustomToolTip = ({ tip }: { tip: string }) => {
  return (
    <Tooltip textAlign='center' padding={4} borderRadius={4}  label={tip} fontSize='md'>
    <QuestionOutlineIcon marginLeft={1} />
  </Tooltip>
  )
};

export default CustomToolTip;