import {
  Image,
  Flex,
} from "@chakra-ui/react";

const FlexSideImage = ({ image }: { image: string }) => {
  return (
    <Flex bgGradient="linear(to-l, blue.200, pink.300)" flex={1}>
      <Image
        opacity={0.4}
        objectPosition={"center"}
        alt={"Login Image"}
        objectFit={"cover"}
        src={image}
      />
    </Flex>
  );
};

export default FlexSideImage;
