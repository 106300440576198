import React, { useState, useEffect } from "react";

import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  Link,
  Spinner,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { emailIsValid } from "../utils";
import { useTranslation } from "react-i18next";
import LogoCompact from "../assets/images/LogoCompact";
import { FlexSideImage, ForgotPasswordModal } from "../components";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showForgotPassowrdModal, setShowForgotPassowrdModal] = useState(false);
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const trans = (key: string) => t(`login.${key}`);
  // @ts-ignore
  const { user, login, recoveryPassword, authLoading, signInWithGoogle } =
    useAuth();

  useEffect(() => {
    if (user) navigate("/admin");
  }, [user]);

  const onLogin = async () => {
    await login(email, password);
  };

  return (
    <>
      {showForgotPassowrdModal && (
        <ForgotPasswordModal
          onClose={() => setShowForgotPassowrdModal(false)}
          onSave={() => recoveryPassword(email)}
        />
      )}

      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <LogoCompact
              width={150}
              height={100}
              color={useColorModeValue("#F63788", "white")}
            />
            <Heading fontSize={"2xl"}>{trans("signInToYourAccount")}</Heading>
            <FormControl id="email">
              <FormLabel>{trans("emailAddress")}</FormLabel>
              <Input onChange={(e) => setEmail(e.target.value)} type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>{trans("password")}</FormLabel>
              <Input
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") onLogin();
                }}
                type="password"
              />
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>{trans("rememberMe")}</Checkbox>
                <Link onClick={() => setShowForgotPassowrdModal(true)}>
                  <Text color="blue.500">{trans("forgotPassword")}</Text>
                </Link>
              </Stack>
              <Divider />

              <Button
                onClick={() => onLogin()}
                colorScheme={"blue"}
                isDisabled={!emailIsValid(email) || !password.length}
                variant={"solid"}
              >
                {authLoading ? <Spinner /> : trans("signIn")}
              </Button>
              {/* <Button
                onClick={() => signInWithGoogle()}
                colorScheme={"blue"}
                variant={"solid"}
              >
                {trans("signInWithGoogle")}
              </Button> */}
            </Stack>
          </Stack>
        </Flex>
        <FlexSideImage image="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80" />
      </Stack>
    </>
  );
}

export default Login;
