import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  InputGroup,
  InputRightElement,
  Box,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";
import { useUtils } from "../context/UtilsContext";

import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

function ChangePasswordModal({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  // @ts-ignore
  const { triggerToast } = useUtils();
  const trans = (key: string) => t(`header.changePasswordModal.${key}`);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [show, setShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    setIsLoading(true);
    if (user) {
      const credential = EmailAuthProvider.credential(
        // @ts-ignore
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credential)
        .then(() => {
          updatePassword(user, newPassword)
            .then(() => {
              triggerToast(t("generic.sucess"), trans("passwordChanged"), true);
              onClose();
            })
            .catch((error) => {
              triggerToast(t("generic.errorUpdateDate"), error.message, false);
            });
        })
        .catch((error) => {
          triggerToast(t("generic.errorUpdateDate"), error.message, false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isLoading ? (
          <Box margin='auto' padding={20} >
            <Spinner margin='auto' />
          </Box>
        ) : (
          <>
            <ModalHeader>{trans("modalTitle")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl marginBottom={4}>
                <FormLabel>{trans("currentPassword")}</FormLabel>
                <InputGroup>
                  <Input
                    type={!show ? "password" : "text"}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setShow(!show)}
                    >
                      {!show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl marginBottom={4}>
                <FormLabel>{trans("newPassword")}</FormLabel>
                <Input
                  type={!show ? "password" : "text"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormControl>
              <FormControl marginBottom={4}>
                <FormLabel>{trans("confirmNewPassword")}</FormLabel>
                <Input
                  type={!show ? "password" : "text"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={onClose}>
                {t("generic.cancel")}
              </Button>
              <Button
                marginLeft={4}
                isDisabled={
                  !currentPassword ||
                  newPassword !== confirmPassword ||
                  !newPassword
                }
                colorScheme="blue"
                mr={3}
                onClick={handleChangePassword}
              >
                {t("generic.save")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
export default ChangePasswordModal;
