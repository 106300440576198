export default {
  translations: {
    generic: {
      addNew: "Add new",
      changeImage: "Change image",
      newImage: "New image",
      remove: "Remove",
      save: "Save",
      seePreview: "Preview",
      errorUpdateDate: "Error updating data",
      tryLater: "Try again later",
      dataUpdated: "Data updated",
      success: "Success",
      error: "Error",
      cancel: "Cancel",
      confirm: "Confirm",
      edit: "Edit",
      close: "Close",
      clear: "Clear",
      title: "Title",
      message: "Message",
      name: "Name",
      text: "Text",
      image: "Image",
      description: "Description",
      show: "Show",
      hide: "Hide",
      copiedToClipboard: "Copied to clipboard",
      errorCopyToClipboard: "Error copying to clipboard: {{err}}",
    },
    content: {
      pageContent: "Page content",
      headerMain: "Initial Session",
      mainTitle: "Title",
      mainSubTitle: "Subtitle",
      mainDescription: "Description",
      heroImage: "Image",
      headerAbout: "About me",
      heroTitle: "About me Title",
      heroSubTitle: "About me Subtitle",
      heroMessage: "About me Message",
      aboutImage: "About me Image",
      headerHowWork: "About your work",
      howWorkTitle: "Specialties and performance title",
      howWorkSubtitle: "Subtitle",
      howWorkMessage: "Message",
      headerTestimonials: "Testimonials",
      testimonialTitle: "Testimonials Title",
      testimonialSubTitle: "Testimonials Subtitle",
      headerBottomSection: "Bottom Session",
      bottomSectionTitle: "Bottom session title",
      bottomSectionSubTitle: "Bottom session subtitle",
      bottomSectionMessage: "Bottom session message",
      backgroundImage: "Background",
      headerSEO: "SEO (Search Engine Optimization)",
      pageTitle: "Page title",
      pageDescription: "Page description",
      onlinePsychotherapy: "Online Psychotherapy",
      SEOImage: "SEO Image",
      SEOKeywords: "SEO Keywords",
      headerFAQS: "Frequently Asked Questions",
      headerExtra: "Extra",
      bottomText: "Bottom message",
      specialties: "Specialties",
      testimonials: "Testimonial",
      therapySteps: "Performance",
      faqs: "Question",
      testimonialsTip: "Here you can add, edit and delete testimonials",
      workTip: "Here you can add, edit and delete specialties and performance.",
      aboutTip: "In this section you can edit information about you.",
      footerTip: "Here you can edit items in the footer of the site.",
      seoTip: "Here you can edit SEO information. (Search Engine Optimization)",
      homeTip:
        "Here you can edit information from the initial session on the main page of your site.",
      testimonialsItens: "Testimonials",
      theraphyStepsItens: "Highlighted performances",
    },
    blog: {
      pageTitle: "Blog Management",
      blogCover: "Blog cover image",
      blogCoverMainTitle: "Blog cover settings",
      blogTitle: "Blog title",
      blogDescription: "Blog description",
      blogPosts: "Blog posts",
      newPost: "New Post",
      editPost: "Edit Post",
      noPosts: "No blog post yet",
      post: "Post title",
      description: "Description",
      postPlaceHolder: "The call to your post",
      descriptionPlaceHolder: "Description of your post",
      slugPlaceHolder: "Will be used to create the URL of your post",
      postCover: "Post image",
      postContent: "Post content",
      slug: "Slug",
      lastUpdate: "Last update",
      postDeleted: "Post successfully deleted",
      postCreated: "Post successfully created",
      postEdited: "Post successfully edited",
      coverTip:
        "Here you can edit details that will make up the blog cover, and will also appear on the homepage of your site.",
      confirmDelete: "Are you sure you want to delete this post?",
      enableBlog: "Enable blog",
      enableBlog_info:
        "If you wish to enable the blog, we will add elements related to your site.",
    },
    mediaCenter: {
      imageDescription: "Image description",
      upload: "Upload",
      clear: "Clear",
      delete: "Delete",
      add: "Use",
      noImages: "No image found",
      selectImage: "Select an image",
      close: "Close",
      addNewImage: "Add new image",
      dragAndDrop: "Click to select or drag and drop an image here",
    },
    config: {
      pageTitle: "Settings",
      whatsappNumber: "Whatsapp number",
      addColor: "Add color",
      changeColor: "Change color",
      howFontWillBeApplied: "How the font will be applied to texts",
      template1: "Content and image",
      template2: "Background image and centered content",
      template3: "Content and image with curve division",
      selectOption: "Select an option",
      primaryColor: "Primary color",
      secondaryColor: "Secondary color",
      primaryFont: "Primary font",
      secondaryFont: "Secondary font",
      template: "Template",
      userDetails: "User details",
      userName: "Name",
      socialMediaWhatsapp: "Whatsapp",
      workFocus: "Work focus",
      address: "Address",
      ProfessionalId: "Professional registration number",
      logoImage: "Logo image",
      socialMedia: "Social media",
      socialMediaFacebook: "Facebook",
      socialMediaInstagram: "Instagram",
      socialMediaTwitter: "Twitter",
      socialMediaLinkedin: "Linkedin",
      socialMediaYoutube: "Youtube",
      technicalDetails: "Technical details",
      googleAnalyticsId: "Google Analytics ID",
      userDomainUrl: "Your site URL",
      visualStyles: "Visual styles",
      socialMediaTip:
        "If you don't have or don't want to add a social network, leave the field blank and we won't show it on your site.",
      yourSocialMediaProfile: "yourProfileOn{{social}}",
      primaryFontUse: "Mainly used in titles and calls",
      secondaryFontUse: "Used in texts and buttons",
      socialMediaWhatsapp_info:
        "Whatsapp number with area code, without spaces. Patients will contact you through this number.",
      address_info:
        "If you do not want to show your address, leave the field empty.",
      workFocus_info: "We will highlight the specialties you serve.",
      primaryColor_info:
        "It will be the main color of your site, mainly used in titles and buttons.",
      secondaryColor_info:
        "Mainly used in smaller texts, backgrounds and others.",
      addressInputPlaceholder: "Street, number, neighborhood, city, state",
      samples: "See more options",
      templateModalTitle: "Check available home head templates",
      colorPicker: "Color picker",
      urlInvalid: "Invalid URL format. Example: yoursite.com",
      urlPlaceholder: "yoursite.com",
    },
    disorders: {
      anxietyDisorders: "Anxiety Disorders",
      moodDisorders: "Mood Disorders",
      eatingDisorders: "Eating Disorders",
      sleepDisorders: "Sleep Disorders",
      personalityDisorders: "Personality Disorders",
      developmentalDisorders: "Developmental Disorders",
      psychosomaticDisorders: "Psychosomatic Disorders",
      stressRelatedDisorders: "Stress-Related Disorders",
      impulseControlProblems: "Impulse Control Problems",
      sexualDisorders: "Sexual Disorders",
      psychoticDisorders: "Psychotic Disorders",
      adjustmentProblems: "Adjustment Problems",
      postTraumaticStressDisorder: "Post-Traumatic Stress Disorder (PTSD)",
      attentionDeficitHyperactivityDisorder:
        "Attention Deficit Hyperactivity Disorder (ADHD)",
      burnoutSyndrome: "Burnout Syndrome",
      adjustmentDisorder: "Adjustment Disorder",
      intermittentExplosiveDisorder: "Intermittent Explosive Disorder",
      kleptomania: "Kleptomania",
      pyromania: "Pyromania",
      erectileDysfunction: "Erectile Dysfunction",
      hypoactiveSexualDesireDisorder: "Hypoactive Sexual Desire Disorder",
      vaginismus: "Vaginismus",
      schizophreniformDisorder: "Schizophreniform Disorder",
      schizoaffectiveDisorder: "Schizoaffective Disorder",
      delusionalDisorder: "Delusional Disorder",
      adjustmentDifficultiesAfterSignificantLifeEvents:
        "Adjustment Difficulties After Significant Life Events",
      pathologicalGambling: "Pathological Gambling",
      compulsiveBuyerDisorder: "Compulsive Buyer Disorder",
    },
    header: {
      toggleNavigation: "Toggle Navigation",
      logOut: "Log Out",
      changePassword: "Change Password",
      useDarkTheme: "Dark Theme",
      useLightTheme: "Light Theme",
      confirmLogout: "Are you sure you want to log out?",
      nav: {
        dashboardContent: "Dashboard Content",
        config: "Settings",
        blogManagement: "Blog Management",
      },
      forgotPassword: {
        modalTitle: "Forgot Password?",
        confirmEmail: "Confirm your email address",
        yourMailPlaceholder: "email@example.com",
      },
      changePasswordModal: {
        modalTitle: "Change Password",
        currentPassword: "Current Password",
        newPassword: "New Password",
        confirmNewPassword: "Confirm New Password",
        passwordChanged: "Password changed successfully",
      },
    },
    login: {
      signInToYourAccount: "Sign in to your account",
      emailAddress: "Email address",
      password: "Password",
      rememberMe: "Remember me",
      forgotPassword: "Forgot password?",
      signIn: "Sign in",
      signInWithGoogle: "Sign in with Google",
    },
    newUser: {
      welcome: "Welcome",
      fillYourData: "We need to fill in some data before continuing",
      crp: "Professional registration number",
      whatsapp: "Whatsapp (with area code)",
      mainImage: "Main image",
      name: "Your name",
      nonRequiredFields: "Non-required fields, you can fill them later",
      letsContinue: "Let's continue",
      googleAnalyticsId: "Google Analytics ID",
      email: "Email",
      aboutImage: "About me image",
      logo: "Logo image",
      readMore: "Read more",
      createSite: "Create site!",
      next: "Next",
      back: "Back",
      goToAdmin: "Go to admin",
      sitePreview: "Site preview",
      siteCreated: "Your site is ready!!!",
      webUrl: "your website URL",
      siteNextSteps:
        "Now you can start editing details of your site. \n\nWhile we haven't linked your final domain, you can use this address to preview changes and share with others. \n\nLet's get started?",
      webUrlTip:
        "If you already have your domain, you can add it here and we will link it soon.",
      whatsappHint:
        "Whatsapp number with area code, without spaces. Patients will contact you through this number.",
      mainImageHint: "Main image of your site, will be used on the homepage.",
      logoHint: "Logo image, will be used in the header of your site.",
      aboutImageHint: "About me image, will be used in the about me section.",
      analyctsHint:
        "You can add your Google Analytics ID here to track your site's traffic.",
      whatsappPlaceholder: "Whatsapp number with area code, without spaces",
      copyUrl: "Copy URL",
      dnsFinish: "It's done! Now we will link your domain to your site. It can take up to 48 hours.",
    },
    dnsModal: {
      title: "Domain configuration",
      explanation:
        "To link your domain to your site, you need to add the following DNS records to your domain provider.",
      dnsRequired:
        "ns1.digitalocean.com \b\b ns2.digitalocean.com \n\n ns3.digitalocean.com",
      dnsFinish: "It's done! Now we will link your domain to your site. It can take up to 48 hours.",
    },
  },
};
