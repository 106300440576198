// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider, fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { useToast } from '@chakra-ui/react';

type User = {
  email: string,
  password: string,
}

export type AuthContextType = {
  user: User,
  login: (email: string, password: string) => void,
  logout: () => void,
}

const AuthContext = createContext<AuthContextType | null>(null);

//@ts-ignore
export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const toast = useToast()

  useEffect(() => {
    //@ts-ignore
    const items = JSON.parse(localStorage.getItem('user'));
    if (items) {
      setUser(items);
    }
  }, []);

  const login = async (email: string, password: string) => {
    setAuthLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // setUser(user)
        localStorage.setItem('user', JSON.stringify(user));
        // @ts-ignore
        localStorage.setItem('token', user?.stsTokenManager?.accessToken);
        // @ts-ignore
        localStorage.setItem('refreshToken', user?.stsTokenManager?.refreshToken);
        // @ts-ignore
        setUser(user)
        return user
      })
      .catch((error) => {

        const errorCode = error.code;
        const errorMessage = error.message;
        toast({
          title: errorCode,
          description: errorMessage,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })

      }).finally(() => {
        setAuthLoading(false)
      });
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        const user = result.user;
        if (user) {
          // @ts-ignore
          fetchSignInMethodsForEmail(auth, user.email)
            .then((signInMethods) => {
              // If the user has previously registered with Google
              if (signInMethods.includes(GoogleAuthProvider.PROVIDER_ID)) {
                localStorage.setItem('user', JSON.stringify(user));
                // @ts-ignore
                setUser(user);
                return user;
              } else {
                throw new Error('User not registered with Google');
              }
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              toast({
                title: errorCode,
                description: errorMessage,
                status: 'error',
                duration: 4000,
                isClosable: true,
              });
            });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        toast({
          title: errorCode,
          description: errorMessage,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const logout = () => {

    signOut(auth).then(() => {
      localStorage.setItem('user', JSON.stringify(null));
      setUser(null)
      window.location.href = '/';
    }).catch((error) => {
      // An error happened.
    });

    // Implement your logout logic here and clear the user
  };

  const recoveryPassword = (email: string) => {
    setAuthLoading(true)
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          title: 'Sucess',
          description: "Check your mail box.",
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        return 'sucess'
      })
      .catch((error) => {
        toast({
          title: 'Something went wrong',
          description: error?.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return error?.message
        // An error occurred while sending the password reset email
      }).finally(() => {
        setAuthLoading(false)
      });
  }


  return (
    //@ts-ignore
    <AuthContext.Provider value={{ user, login, logout, recoveryPassword, authLoading, signInWithGoogle }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
