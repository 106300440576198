import { ButtonGroup, Editable, EditablePreview, EditableTextarea, Flex, IconButton, useEditableControls, useToast } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext';
import { editImage } from '../services/api';

// @ts-ignore
const EditableInput = ({ image }: { image: { imageURL: string, id: string, description: string } }) => {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='center' size='sm'>
        <IconButton icon={<CheckIcon />} aria-label="Submit" {...getSubmitButtonProps()} />
        <IconButton icon={<CloseIcon />} aria-label="Cancel" {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent='center'>
        <IconButton size='sm' icon={<EditIcon />} aria-label="Edit" {...getEditButtonProps()} />
      </Flex>
    )
  }
  const { user } = useAuth() || {};
  const toast = useToast()

  const handleSubmit = async (value: string) => {

    const token = (user as any)?.stsTokenManager?.accessToken;
    const newImages =  await editImage(token, value, image.id);
    if (newImages === 'error') return toast({ title: 'Error', description: 'Something went wrong', status: 'error', duration: 3000, isClosable: true, })
  };

  return (
    <Editable placeholder='Image description' onSubmit={(e) => handleSubmit(e)} onCancel={() => console.log('canceled')} defaultValue={image?.description}>
      <EditablePreview />
      <EditableTextarea />
      <EditableControls />
    </Editable>
  )
};

export default EditableInput;