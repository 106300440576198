import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
  Input,
  ModalHeader,
  FormLabel,
  useToast,
  SimpleGrid,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { emailIsValid } from "../utils";

const ForgotPasswordModal = ({
  onClose,
  onSave,
}: {
  onSave: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const trans = (key: string) => t(`header.forgotPassword.${key}`);
  const toast = useToast();

  const handleSave = async () => {
    onSave();
    onClose();
  };


  const handleMainContentModal = () => (
    <>
      <ModalHeader>{trans("modalTitle")}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <SimpleGrid columns={2} spacing={4}>
          <Box>
            <FormLabel>{trans("confirmEmail")}</FormLabel>
            <Input
              placeholder={trans("yourMailPlaceholder")}
              value={email}
              onChange={(value) =>
                setEmail(value.target.value)
              }
            />
            <br />
          </Box>
        </SimpleGrid>
        <br />
        <Divider />

      </ModalBody>
      <ModalFooter>
        <Button isDisabled={!emailIsValid(email)} colorScheme="blue" marginLeft={4} onClick={() => handleSave()}>
          {t("generic.save")}
        </Button>
      </ModalFooter>
    </>
  );

  return (
    <Modal
      scrollBehavior="inside"
      onClose={() => onClose()}
      isOpen={true}
      size="6xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        {handleMainContentModal()}
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
