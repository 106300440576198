export default {
  translations: {
    generic: {
      addNew: "Adicionar novo",
      changeImage: "Alterar imagem",
      newImage: "Nova imagem",
      remove: "Remover",
      save: "Salvar",
      seePreview: "Pré-visualização",
      errorUpdateDate: "Erro ao atualizar dados",
      tryLater: "Tente novamente mais tarde",
      dataUpdated: "Dados atualizados",
      sucess: "Sucesso",
      error: "Erro",
      cancel: "Cancelar",
      confirm: "Confirmar",
      edit: "Editar",
      close: "Fechar",
      clear: "Limpar",
      title: "Título",
      message: "Mensagem",
      name: "Nome",
      text: "Texto",
      image: "Imagem",
      description: "Descrição",
      show: "Mostrar",
      hide: "Esconder", 
      copiedToClipboard: "Copiado para a área de transferência",
      errorCopyToClipboard: "Erro ao copiar para a área de transferência: {{err}}",
    },
    content: {
      pageContent: "Conteúdo da página",

      headerMain: "Sessão Inicial",
      mainTitle: "Título",
      mainSubTitle: "Subtítulo",
      mainDescription: "Descriçao",
      heroImage: "Imagem",

      headerAbout: "Sobre mim",
      heroTitle: "Sobre mim Título ",
      heroSubTitle: "Sobre mim Subtítulo",
      heroMessage: "Sobre mim Mensagem",
      aboutImage: "Sobre mim Imagem",

      headerHowWork: "Sobre seu trabalho",
      howWorkTitle: "Especialidades e atuação titulo",
      howWorkSubtitle: "subtitulo",
      howWorkMessage: "Mensagem",

      headerTestimonials: "Depoimentos",
      testimonialTitle: "Depoimentos Título",
      testimonialSubTitle: "Depoimentos Subtítulo",

      headerBottomSection: "Sessão inferior",
      bottomSectionTitle: "Título sessão inferior",
      bottomSectionSubTitle: "Subtítulo sessão inferior",
      bottomSectionMessage: "Mensagem sessão inferior",
      backgroundImage: "Plano de fundo",

      headerSEO: "SEO (Otimização de mecanismo de pesquisa)",
      pageTitle: "Título da página",
      pageDescription: "Descrição da página",
      onlinePsychotherapy: "Psicoterapia online",
      SEOImage: "Imagem SEO",
      SEOKeywords: "Palavras-chave SEO",
      headerFAQS: "Perguntas frequentes",
      headerExtra: "Extra",
      bottomText: "Mensagem inferior",
      specialties: "Especialidades",

      testimonials: "Depoimento",
      theraphySteps: "Atuação",
      faqs: "Pergunta",


      testimonialsTip: "Aqui você pode adicionar, editar e excluir depoimentos",
      workTip:
        "Aqui você pode adicionar, editar e excluir especialidades e atuações.",
      aboutTip: "Nessa seção você pode editar informações sobre você.",
      footerTip: "Aqui você pode editar items no rodapé do site.",
      seoTip:
        "Aqui você pode editar informações de SEO. (Otimização de mecanismo de pesquisa)",
      homeTip:
        "Aqui você pode editar informações da sessão inicial na página principal do seu site.",
        testimonialsItens: "Depoimentos",
        theraphyStepsItens: "Atuações destacadas",
    },
    blog: {
      pageTitle: "Gerenciamento do blog",
      blogCover: "Imagem de capa do blog",
      blogCoverMainTitle: "Configurações capa do blog",
      blogTitle: "Título do blog",
      blogDescription: "Descrição do blog",
      blogPosts: "Posts do blog",
      newPost: "Novo Post",
      editPost: "Editar Post",
      noPosts: "Nenhum post no blog ainda",
      post: "Título do post",
      description: "Descrição",
      slug: "Slug",
      postPlaceHolder: "A chamada para o seu post",
      descriptionPlaceHolder: "Descrição do seu post",
      slugPlaceHolder: "Será usado para criar a URL do seu post",
      postCover: "Imagem do post",
      postContent: "Conteúdo do post",
      lastUpdate: "Última atualização",
      postDeleted: "Post excluído com sucesso",
      postCreated: "Post criado com sucesso",
      postEdited: "Post editado com sucesso",
      coverTip:
        "Aqui você pode editar detalhes que irão compor a capa do blog, e também aparecerá na página inicial do seu site.",
      confirmDelete: "Tem certeza que deseja excluir este post?",
      enableBlog: "Habilitar blog",
      enableBlog_info: 'Caso deseje habilitar o blog, vamos adicionar elementos relacionados ao seu site.',
    },
    mediaCenter: {
      imageDescription: "Descrição da imagem",
      upload: "Enviar",
      clear: "Limpar",
      delete: "Deletar",
      add: "Usar",
      noImages: "Nenhuma imagem encontrada",
      selectImage: "Selecione uma imagem",
      close: "Fechar",
      addNewImage: "Adicionar nova imagem",
      dragAndDrop: "Click para selecionar ou arraste e solte uma imagem aqui",
    },
    config: {
      pageTitle: "Configurações",
      whatsappNumber: "Número de Whatsapp",
      addColor: "Adicionar cor",
      changeColor: "Alterar cor",
      howFontWillBeApplied: "Como a fonte será aplicada aos textos",
      template1: "Conteúdo e imagem",
      template2: "Imagem de fundo e conteúdo centralizado",
      template3: "Conteúdo e imagem com divisão em curvas",
      selectOption: "Selecione uma opção",
      primaryColor: "Cor primária",
      secondaryColor: "Cor secundária",
      primaryFont: "Fonte primária",
      secondaryFont: "Fonte secundária",
      template: "Template",
      userDetails: "Detalhes do usuário",
      userName: "Nome",
      socialMediaWhatsapp: "Whatsapp",
      workFocus: "Foco de trabalho",
      address: "Endereço",
      ProfessionalId: "Número de registro profissional",
      logoImage: "Imagem do logo",
      socialMedia: "Redes sociais",
      socialMediaFacebook: "Facebook",
      socialMediaInstagram: "Instagram",
      socialMediaTwitter: "Twitter",
      socialMediaLinkedin: "Linkedin",
      socialMediaYoutube: "Youtube",
      technicalDetails: "Detalhes técnicos",
      googleAnalyticsId: "ID do Google Analytics",
      userDomainUrl: "URL do seu site",
      visualStyles: "Estilos visuais",
      socialMediaTip:
        "Caso não tenha ou não queira adicionar alguma rede social, deixe o campo vazio que não vamos mostrar no seu site.",
      yourSocialMediaProfile: "seuPerfilNo{{social}}",
      primaryFontUse: "Utilizada principalmente em títulos e chamadas",
      secondaryFontUse: "Utilizada em textos e botões",
      socialMediaWhatsapp_info: "Número de Whatsapp com DDD, sem espaços. Os pacientes entrarão em contato com você através desse número.",
      address_info: "Caso não queira divulgar seu endereço, deixe o campo vazio.",
      workFocus_info: "Vamos destacar as especialidades que você atende.",
      primaryColor_info: 'Será a cor principal do seu site, utilizada principalmente em títulos e botões.',
      secondaryColor_info: 'Utilizada principalmente em textos menores, fundos e outros.',
      addressInputPlaceholder: "Rua, número, bairro, cidade, estado",
      samples: "Ver mais modelos",
      templateModalTitle: "Veja modelos de templates",
      colorPicker: "Seletor de cores",
      dnsInstructions: "Como configurar seu domínio",
      urlInvalid: "Formato de URL inválido. Exemplo: seudominio.com",
      urlPlaceholder: "seudominio.com",
    },
    disorders: { 
      anxietyDisorders: "Transtornos de Ansiedade",
      moodDisorders: "Transtornos do Humor",
      eatingDisorders: "Transtornos Alimentares",
      sleepDisorders: "Transtornos do Sono",
      personalityDisorders: "Transtornos de Personalidade",
      developmentalDisorders: "Transtornos do Desenvolvimento",
      psychosomaticDisorders: "Transtornos Psicossomáticos",
      stressRelatedDisorders: "Transtornos Relacionados ao Estresse",
      impulseControlProblems: "Problemas de Controle de Impulsos",
      sexualDisorders: "Transtornos Sexuais",
      psychoticDisorders: "Transtornos Psicóticos",
      adjustmentProblems: "Problemas de Adaptação",
      postTraumaticStressDisorder:
        "Transtornos de Estresse Pós-Traumático (TEPT)",
      attentionDeficitHyperactivityDisorder:
        "Transtorno de Déficit de Atenção e Hiperatividade (TDAH)",
      burnoutSyndrome: "Síndrome de Burnout",
      adjustmentDisorder: "Transtorno de Ajustamento",
      intermittentExplosiveDisorder: "Transtorno Explosivo Intermitente",
      kleptomania: "Cleptomania",
      pyromania: "Piromania",
      erectileDysfunction: "Disfunção Erétil",
      hypoactiveSexualDesireDisorder: "Transtorno do Desejo Sexual Hipoativo",
      vaginismus: "Vaginismo",
      schizophreniformDisorder: "Transtorno Esquizofreniforme",
      schizoaffectiveDisorder: "Transtorno Esquizoafetivo",
      delusionalDisorder: "Transtorno Delirante",
      adjustmentDifficultiesAfterSignificantLifeEvents:
        "Dificuldades de Ajuste após Eventos de Vida Significativos",
      pathologicalGambling: "Transtorno do Jogo Patológico",
      compulsiveBuyerDisorder: "Transtorno do Comprador Compulsivo",
    },
    header: {
      toggleNavigation: "Alternar Navegação",
      logOut: "Sair",
      changePassword: "Alterar senha",
      useDarkTheme: "Tema escuro",
      useLightTheme: "Tema claro",
      confirmLogout: "Tem certeza que deseja sair?",
      nav: {
        dashboardContent: "Conteúdo do Painel",
        config: "Configurações",
        blogManagement: "Gerenciamento do Blog",
      },
      forgotPassword: {
        modalTitle: "Esqueceu a senha?",
        confirmEmail: "Confirme seu endereço de e-mail",
        yourMailPlaceholder: "email@exemplo.com",
      },
      changePasswordModal: {
        modalTitle: "Alterar senha",
        currentPassword: "Senha atual",
        newPassword: "Nova senha",
        confirmNewPassword: "Confirme a nova senha",
        passwordChanged: "Senha alterada com sucesso",
      },
    },
    login: {
      signInToYourAccount: "Faça login na sua conta",
      emailAddress: "Endereço de email",
      password: "Senha",
      rememberMe: "Lembrar-me",
      forgotPassword: "Esqueceu a senha?",
      signIn: "Entrar",
      signInWithGoogle: "Entrar com Google",
    },
    newUser: {
      welcome: "Bem-vinda(o)!",
      fillYourData: "Vamos precisar preencher alguns dados antes de continuar",
      crp: "CRP",
      whatsapp: "Whatsapp (com DDD)",
      mainImage: "Imagem principal",
      name: "Seu nome",
      nonRequiredFields: "Campos não obrigatórios, você pode preencher depois",
      letsContinue: "Vamos continuar",
      googleAnalyticsId: "ID do Google Analytics",
      email: "Email",
      aboutImage: "Imagem sobre mim",
      logo: "Imagem do logo",
      readMore: "Saiba mais",
      createSite: "Criar site!",
      next: "Próximo",
      back: "Voltar",
      goToAdmin: "Ir para o administrador",
      sitePreview: "Pré-visualização do site",
      siteCreated: "Seu site está pronto !!!",
      webUrl: "URL do seu site",
      siteNextSteps: "Agora você pode começar a editar detalhes do seu site. \n\nEnquanto nao vinculamos o seu domínio final, você pode usar esse endereço para visualizar alterações e compartilhar com quem queira. \n\nVamos começar?",
      webUrlTip: "Se você já tem seu domínio, pode adicionar aqui que vamos fazer o link em breve.",
      whatsappHint: "Número de Whatsapp com DDD, sem espaços. Os pacientes entrarão em contato com você através desse número.",
      mainImageHint: "Imagem principal do seu site, será usada na página inicial.",
      logoHint: "Imagem do logo, será usada no cabeçalho do seu site.",
      aboutImageHint: "Imagem sobre mim, será usada na sessão sobre mim.",
      analyctsHint: "Você pode adicionar o ID do Google Analytics aqui, para acompanhar o tráfego do seu site.",
      whatsappPlaceholder: "Número de Whatsapp com DDD, sem espaços",
      copyUrl: "Copiar URL",
      dnsInstructions: "Como configurar seu domínio",

    },
    dnsModal: {
      title: "Configuração de domínio",
      explanation:
        "Para vincular seu domínio ao seu site, você precisa adicionar os seguintes registros DNS ao seu provedor de domínio.",
      dnsRequired:
        "ns1.digitalocean.com \nns2.digitalocean.com \nns3.digitalocean.com",
      dnsFinish: "Pronto! Agora vamos vincular seu domínio ao seu site. Pode levar até 48 horas.",
    },
  },
};
