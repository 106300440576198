

import { useState, useEffect } from 'react'
import {
  Card, Box, Heading, CardBody, Stack,
  StackDivider, SimpleGrid, FormControl, FormLabel, Input,
  Button,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react'
import { editContent, getData } from '../services/api'
import { useAuth } from '../context/AuthContext';
import { useUtils } from '../context/UtilsContext';
import { CustomImage, CustomToolTip, ImageDetailModal, MediaCenter, PreviewButton } from '../components';
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const { t } = useTranslation()
  const trans = (key: string) => t(`content.${key}`)
  const navigate = useNavigate();


  // @ts-ignore
  const { user, logout } = useAuth();
  // @ts-ignore
  const { triggerToast, setUserImage } = useUtils();

  const main = {
    mainTitle: "",
    mainSubTitle: "",
    mainDescription: "",
  }

  const about = {
    heroTitle: "",
    heroSubTitle: "",
    heroMessage: "",
  }

  const howWork = {
    howWorkTitle: "",
    howWorkSubtitle: "",
    howWorkMessage: "",
  }
  const hero = {
    heroTitle: "",
    heroSubTitle: "",
    heroMessage: "",
  }

  const testimonials = {
    testimonialTitle: "",
    testimonialSubTitle: "",
  }
  const bottomSection = {
    bottomSectionTitle: "",
    bottomSectionSubTitle: "",
    bottomSectionMessage: "",
  }

  const seo = {
    pageTitle: "",
    pageDescription: "",
    SEOKeywords: "",
  }

  const extra = {
    bottomText: "",
    specialties: "",
  }

  const emptyState = {
    ...main,
    ...howWork,
    ...hero,
    ...testimonials,
    ...bottomSection,
    bottomSectionBackgroundImage: "",
    ...seo,
    pageSEOImage: "",
    ...extra,
  }

  const token = user?.stsTokenManager?.accessToken
  const [texts, setTexts] = useState(emptyState)
  const [fullData, setFullData] = useState(null)
  const [imageKey, setImageKey] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showImageModal, setShowImageModal] = useState('')
  const [contentId, setContentId] = useState('')

  const getDataFromApi = async () => {
    setIsLoading(true)
    const data = await getData(token)
    if (data.isNewUser) return navigate('/newuser')
    if (data?.error === "Unauthorized") return logout()
    setUserImage(data?.images?.hero?.url || '')
    setTexts(data.texts)
    setFullData(data)
    setContentId(data.id)
    setIsLoading(false)
  }

  const sendChange = async () => {
    setIsLoading(true)
    const request = await editContent(token, {
      // @ts-ignore
      ...fullData,
      texts: texts
    }, contentId)


    if (!request?.error) triggerToast(t("generic.sucess"), '', true)
    else triggerToast(t("generic.errorUpdateDate"), t("generic.tryLater"), false)
    setIsLoading(false)
  }

  useEffect(() => {
    getDataFromApi()
  }, []);

  const handleField = (array: any[]) => {
    return Object.entries(array).map((e) => {
      return <FormControl key={`${e[0]}_`} >
        <FormLabel>{trans(e[0])}</FormLabel>
        {/* @ts-ignore */}
        <Textarea placeholder='' value={texts[e[0]]} onChange={(value) => setTexts({ ...texts, [e[0]]: value.target.value })} />
      </FormControl>
    }
    )
  }
  const handleFieldGeneric = (array: any[], key: string, objIndex: number) => {
    return Object.entries(array).map((e) => {
      return <FormControl key={`${key}${objIndex}__`} >

        <FormLabel>{t(`generic.${e[0]}`)}</FormLabel>
        {e[0] === 'image' && drawImageInputForGeneric(key, 'Image', objIndex)}

        {/* @ts-ignore */}
        {e[0] !== 'image' && <Textarea placeholder='' value={e[1]} onChange={(value) => {
          {/* @ts-ignore */ }
          let items = fullData[key]
          items[objIndex][e[0]] = value.target.value
          // @ts-ignore
          setFullData({ ...fullData, [key]: items })
        }} />}
      </FormControl>
    }
    )
  }

  const removeItem = (key: string, index: number) => {
    // @ts-ignore
    let items = fullData[key]
    items.splice(index, 1)
    // @ts-ignore
    setFullData({ ...fullData, [key]: items })
  }

  const addNewItem = (key: string) => {
    // @ts-ignore
    let items = fullData[key]
    if (!items) items = []
    let newObject = {}

    if (key === 'theraphySteps') newObject = {
      title: "",
      description: "",
      image: "",
    }
    if (key === 'testimonials') newObject = {
      text: "",
      name: "",
    }
    if (key === 'faqs') newObject = {
      title: "",
      message: "",
    }
    items.push(newObject)
    // @ts-ignore
    setFullData({ ...fullData, [key]: items })
  }

  const handleImageChange = (url: string, description?: string) => {
    if (imageKey.includes('[')) {
      const key = imageKey.split('[')[0]
      const index = imageKey.split('[')[1].split(']')[0]
      // @ts-ignore
      let output = fullData[key]
      // @ts-ignore
      output[index].image = url
      return setFullData({
        // @ts-ignore
        ...fullData, [key]: output
      })
    }
    else setFullData({
      // @ts-ignore
      ...fullData,
      images: {
        // @ts-ignore
        ...fullData.images,
        [imageKey]: {
          description,
          url
        }
      }
    })
    setImageKey('')
    setShowModal(false)
  }

  const openMediaCenter = (key: string, genericIndex?: number) => {
    const realIndex = genericIndex ? genericIndex - 1 : 0
    setImageKey(`${key}${genericIndex ? `[${realIndex}]` : ''}`)
    setShowModal(true)
  }

  const drawImage = (imageUrl: string) => imageUrl && <CustomImage image={imageUrl} onClick={(e: string) => setShowImageModal(e)} />
 
  const drawImageInput = (key: string, label: string) => <FormControl>
    <FormLabel>
      {label || key}
    </FormLabel>
    {/* @ts-ignore */} 
    {  drawImage(fullData?.images[key]?.url)}
    {/* @ts-ignore */}
    <Button onClick={() => openMediaCenter(key)}>{fullData?.images[key]?.url ? t('generic.changeImage') : t('generic.newImage')} </Button>
  </FormControl>

  const drawImageInputForGeneric = (key: string, label: string, index: number) => <FormControl>
    <Box display='flex'>
      {/* @ts-ignore */}
      {drawImage(fullData?.[key][index]?.image)}
      <Button marginLeft='10px' onClick={() => openMediaCenter(key, index + 1)}>{t('generic.changeImage')}</Button>
    </Box>
  </FormControl>
  const bgColor = useColorModeValue('gray.100', 'gray.900')
  const contentColor = useColorModeValue('white', 'gray.400')
  const handleInternalCollections = (key: string) => {
    if (!fullData) return
    const data = fullData[key]

    // @ts-ignore
    const drawCard = () => data?.map((e: any, index: number) => {
      const content = e?.text || e?.description || e?.message;
      const formattedText = content?.length > 200 ? content.slice(0, 200) + '...' : content;
      return <>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                # {index + 1} {trans(key)} |  {e?.name || e?.title} - {formattedText}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel backgroundColor='gray.300' borderWidth={2} padding={4}>
            <Box backgroundColor={contentColor} borderColor='gray.200' borderWidth={2} padding={6} borderRadius={8}>
              <SimpleGrid columns={2} spacing={10}>
                {/* @ts-ignore */}
                {handleFieldGeneric(e, key, index)}
              </SimpleGrid>
              <Box w='100%' mt='20px' textAlign='end'>
                <Button margin='auto' onClick={() => removeItem(key, index)} marginLeft='auto' w='fit-content' colorScheme='red'>{t('generic.remove')}</Button>
              </Box>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </>
    })

    return <>
      <Accordion allowToggle allowMultiple>
        {drawCard()}
      </Accordion>
      <br />  
      <Button onClick={() => addNewItem(key)} marginLeft='auto' w='fit-content' margin='auto' colorScheme='blue'>{t('generic.addNew')}</Button>
      <br />
    </>
  }

  return (
    <>
      {showModal && <MediaCenter onClose={() => setShowModal(false)} selectImage={(e, x) => handleImageChange(e, x)} />}
      {showImageModal && <ImageDetailModal onClose={() => setShowImageModal('')} image={showImageModal} />}
      {isLoading && !fullData ? <Box w='100%' textAlign='center' marginTop='100px' ><Spinner margin='auto' /></Box> :
        <Box w='100%' h='100%' bgPosition='' padding={4} backgroundColor={bgColor} >
          <SimpleGrid margin={4} columns={2} spacing={10}>
            <h1>{trans('pageContent')}</h1>
            <Box w='fit-content' marginLeft='auto' >
              <PreviewButton url={contentId} />
              <Button onClick={() => sendChange()} marginLeft='auto' w='fit-content' colorScheme='blue'>{isLoading ? <Spinner size='xs' /> : t('generic.save')}</Button>
            </Box>
          </SimpleGrid>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerMain')}
                    <CustomToolTip tip={trans('homeTip')} />
                  </Heading>

                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(main)}
                  {drawImageInput('hero', trans('heroImage'))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerAbout')}
                    <CustomToolTip tip={trans('aboutTip')} />
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(about)}
                  {drawImageInput('about', trans('aboutImage'))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerHowWork')}
                    <CustomToolTip tip={trans('workTip')} />
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(howWork)}
                </SimpleGrid>
              </Stack>
            </CardBody>
            <CardBody>
              <FormLabel marginBottom='-10px'>{trans('theraphyStepsItens')}</FormLabel>
            </CardBody>
            {handleInternalCollections('theraphySteps')}
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerTestimonials')}
                    <CustomToolTip tip={trans('testimonialsTip')} />
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  <>
                    {/* @ts-ignore */}
                    {handleField(testimonials)}
                  </>
                </SimpleGrid>
              </Stack>
            </CardBody>
            <CardBody>
              <FormLabel marginBottom='-10px'>{trans('testimonialsItens')}</FormLabel>
            </CardBody>
            {handleInternalCollections('testimonials')}
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerBottomSection')}
                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */} 
                  {handleField(bottomSection)}
                  {drawImageInput('bottomSectionBackgroundImage', trans('backgroundImage'))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerFAQS')}
                  </Heading>
                </Box>

              </Stack>
            </CardBody>
            {handleInternalCollections('faqs')}
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerSEO')}
                    <CustomToolTip tip={trans('seoTip')} />

                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(seo)}
                  {drawImageInput('pageSEOImage', trans('SEOImage'))}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
          <Card margin={4}>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    {trans('headerExtra')}
                    <CustomToolTip tip={trans('footerTip')} />

                  </Heading>
                </Box>
                <SimpleGrid columns={2} spacing={10}>
                  {/* @ts-ignore */}
                  {handleField(extra)}
                </SimpleGrid>
              </Stack>
            </CardBody>
          </Card>
        </Box>}

    </>
  )
}
