export function isColorDark(color: string) {
    // Convert the hex color to an RGB color
    const hexToRgb = (hex: string) => {
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return [r, g, b];
    };
    const [r, g, b] = hexToRgb(color.replace('#', ''));
  
    // Calculate the relative luminance of the color
    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
  
    // Return true if the color is dark, false if it is light
    return luminance < 0.5;
  }


  export const emailIsValid = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

  export const convertObjectToArray = (obj: any) => {
    return Object.keys(obj).map((key) => obj[key]);
  }


  export const providedUrlStringIsValid = (url?: string) => url ? /^(\w+)\.(\w+)$/.test(url) : true;

  export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  }

  export const handleCRPInput = (e: string) => e.replace(/^(\d{2})(\d*)$/, "$1/$2");